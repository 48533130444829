@import '../../index.css';

.sideLinks {
    /* background-color: var(--background-color1); */
    /* padding: 20px; */
}

.hashtags {
    padding: 0 20px;
}

/* .connectionTop{

} */
.dropdown {
    width: 18px;
    height: 18px;
    background-color: var(--gray-blue);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.dropdown svg {
    color: var(--buttonBase);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.personal {
    background-color: var(--border);
}

.navLinks {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.navLinks span {
    color: var(--text-color1);
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}

.listLink {
    border-bottom: 1px solid var(--border);
    padding-bottom: 20px;
}

.listLink .active span {
    color: var(--text-color2) !important;
    font-weight: bold;
}

.header1 a {
    font-size: 14px
}

.heading {
    color: var(--gray);
    font-size: 14px;
    font-weight: 400;
}

.imgBg {
    background: white;
    padding: 7px;
    border-radius: 50px;
}

.imgBg1 {
    /* background: white; */
    /* padding: 7px; */
    border-radius: 50px;
}

.pointer {
    cursor: pointer;
}

.listLink span.profile-name {
    color: var(--text-color) !important;
    font-size: 12px;
    display: block;
}

.lastseen {
    margin-left: auto;
    font-size: 12px;
    color: var(--gray) !important;
}

.circle-container {
    position: relative;
    height: 20px;
    width: 20px;
}

.circle-container img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
}

.circle-container img:nth-child(1) {
    left: 0;
}

.circle-container img:nth-child(2) {
    left: 15px;
    z-index: 1;
}

.circle-container img:nth-child(3) {
    left: 30px;
    z-index: 2;
}

.circle-container img:nth-child(4) {
    left: 45px;
    z-index: 3;
}

.circle-container .circle-img:last-child {
    margin-right: 10px;
}



/* .circle-container {
    position: relative;
    height: 30px;
}

.circle-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 30;
    left: 0;
}

.circle-img:nth-child(2) {
    left: 10px;
    z-index: 1;
}

.circle-img:nth-child(3) {
    left: 20px;
    z-index: 1;
}

.circle-img:nth-child(4) {
    left: 0px;
} */

.plus-icon {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 24px;
    font-weight: bold;
}

.text {
    font-size: 10px;
    color: var(--gray);
}

.sideLinks .search-container {
    width: 100%;
    position: sticky;
    bottom: 0;
}


.sub-content-container .userProductContainer::-webkit-scrollbar,
.jobMainContainer .advertiseImage::-webkit-scrollbar,
.sub-content-container .mobile-view::-webkit-scrollbar,
.mainProfileContainer .profileTabContent::-webkit-scrollbar,
.mainProfileContainer .newsfeedPosts.ad-container::-webkit-scrollbar,
.scroller::-webkit-scrollbar,
.scroller1::-webkit-scrollbar,
.message-scroller::-webkit-scrollbar,
.chat-main-content::-webkit-scrollbar,
.notificationscroller::-webkit-scrollbar,
.ImagesliderScroller::-webkit-scrollbar,
.sideLinks.leftNav::-webkit-scrollbar,
.jobdetail-right::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.scroller {
    height: 38vh;
    overflow: scroll;
}

.notificationscroller {
    /* height: 50vh; */
    overflow: scroll;
}

.scroller1 {
    height: 38vh;
    overflow: scroll;
    padding-bottom: 60px;
}

.sideLinks {
    position: fixed;
    height: 100%;
    width: 18%;
    padding-bottom: 70px;
}

.sideLinks.leftNav {
    overflow: scroll;
    padding: 20px;
}

.msgRight .material-symbols-sharp {
    color: var(--gray) !important;
}

.sign-up-text {
    color: var(--gray);
}

/* .recomend-cont {
    margin-right: 30px;
}

.recommnedationLinks {
    width: 18%;
} */

.dropdown svg.material-symbols-outlined {
    position: unset !important;
    transform: translate(0);
}