@import '../../index.css';

.modal-content {
    /* width: 625px; */
    /* height: 450px; */
}

.lableStyle {
    color: var(--gray);
    font-size: 14px;
}

.form-control:focus {
    outline: none;
    border: none;
    box-shadow: none
}

.form-control::placeholder {
    font-size: 14px;
}

.modal-header {
    padding: 0px 20px;
    /* border: none; */
}

.progress-bar-yellow .progress-bar {
    background-color: #EED600;
}

.progress-bar-blue .progress-bar {
    background-color: var(--link-color);
}

.progress-bar-green .progress-bar {
    background-color: #007B05;
}

.progress {
    width: 100% !important;
}

.error_msg_lbl {
    color: red;
    font-size: 12px;
}

.form-progress-cont span {
    color: #000;
}

.empty-line {
    height: 1px;
    width: 400px;
    background-color: var(--border);
}

.profile-upload {
    border-bottom: none;
    border: none;
}

.uploadImage-cont img {
    width: 35px;
    height: 35px;
}

.upload-image-text {
    color: var(--gray);
}

.uploaded-image {
    width: 100px;
    height: 100px;
}

.uploaded-image img {
    width: 100%;
}

.previousBtn {
    border: none;
    background: transparent;
    color: var(--buttonBase);
}

.previousBtnCont .material-symbols-sharp {
    color: var(--buttonBase) !important;
}

.theme-bg {
    background-color: green;
}

input {
    outline-style: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.setting-header h4 {
    font-size: 19px;
}

input {
    color: var(--heading-color) !important;
}