@import "../../index.css";

.card-container {
  /* width: 250px; */
  /* height: 1000px; */
  padding: 5px;
  border: 0.2px solid grey;
  background-color: var(--background-color);
}

/* Applied job */

.history-main-text {
  color: var(--heading-color);
}

.history-sub-text {
  color: var(--heading-color);
}

.job-image-cont {
  width: 40px;
  height: 40px;
  background-color: #fc5a5a;
  position: relative;
}

.job-history-card {
  width: 240px;
  height: 210px;
  border: 1px solid var(--border-color);
}

.history-days {
  color: #b5b5be;
}

.attachment-cont h5 {
  color: #b5b5be;
}

.attachment-cont .material-symbols-sharp {
  color: #b5b5be !important;
}

.job-card-icon .material-symbols-sharp {
  color: #b5b5be !important;
}

.seeAllBtn button {
  width: 240px;
  height: 38px;
  border: none;
  background-color: #f1f1f5;
  color: #696974;
  font-size: 12px;
  font-weight: 600;
}

/* Saved Job */

/* .saved-job-cont {
  height: 670px;
}

.job-search-cont {
  height: 100px;
} */

.search-main-text {
  width: 122px;
}

.close-icon .material-symbols-sharp {
  color: #fc5a5a !important;
}

/* Job Offers */

/* .job-offers-container {
  height: 785px;
} */

.contract-text {
  color: var(--gray);
}

.contract-sub {
  width: 105px;
}

.wljPrimaryBtn button {
  background-color: var(--text-color4);
  color: var(--background-color3);
  font-size: 10px;
}

.rejectBtn button {
  font-size: 11px;
}

.job-offers-card {
  height: 335px;
}

.contract-sub-text {
  width: 150px;
}

.contract-sub-text {
  color: var(--heading-color);
}

/* Interview section */

.interview-container {
  height: 785px;
}

.meeting-website .material-symbols-sharp {
  margin-right: 5px;
  font-size: 15px !important;
  color: var(--gray) !important;
}

.meeting-website h3 {
  color: var(--gray);
}

.history-sub-text .material-symbols-sharp {
  color: var(--gray) !important;
  font-size: 15px !important;
  margin-right: 5px;
}

.sub-image {
  width: 20px;
  height: 20px;
  background-color: #fc5a5a;
  border-radius: 100%;
  border: 1.4px solid #ffff;
  position: absolute;
  left: 10px;
  bottom: -10px;
}

.mobT {
  display: none;
}

.deskT {
  display: flex;
}

.job-history {
  /* padding: 0 40px 0 60px; */
}

.job-history.mobT {
  padding: 0;
}

@media only screen and (max-width: 414px) {
  .card-container {
    margin-bottom: 10px;
  }

  .job-history-card {
    border: 0.1px solid #f1f1f5;
  }

  .mobT {
    display: block;
  }

  .deskT {
    display: none;
  }

  .job-history .card-container {
    margin: auto;
  }
}