@font-face {
  font-family: "Poppins-Bold";
  src: url(./assets/Fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(./assets/Fonts/Poppins-Regular.ttf);
}

/* @font-face {
  font-family: 'Material Icons Sharp';
  src: url('./assets/Fonts/MaterialIconsSharp-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Material Symbols Sharp";
  src: url("./assets/Fonts/MaterialIconsOutlined-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Material Symbols Sharp Filled";
  src: url("./assets/Fonts/MaterialIconsSharp-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
} */



html {
  overflow-y: scroll;
}

body.modal-open {
  overflow: hidden;

}

html.modal-open {
  overflow: hidden;
}

.material-icons {
  font-family: 'Material Icons Sharp', sans-serif;
}

.material-symbols-sharp {
  font-family: "Material Symbols Sharp", sans-serif;
  font-variation-settings: "FILL" 0,
    /* Set FILL to 0 for outlined icons */
    "wght" 400, "GRAD" 0, "opsz" 48;
  cursor: pointer;
}


.material-symbols-sharp-filled {
  font-family: "Material Symbols Sharp Filled", sans-serif;
  font-variation-settings: "FILL" 1,
    /* Set FILL to 0 for outlined icons */
    "wght" 400, "GRAD" 0, "opsz" 48;
  cursor: pointer;
}

.material-symbols-sharp-unfilled {
  font-family: "Material Symbols Sharp", sans-serif;
  font-variation-settings: "FILL" 0,
    /* Set FILL to 0 for outlined icons */
    "wght" 400, "GRAD" 0, "opsz" 48;
  cursor: pointer;
}

.material-symbols-sharp.bookmark {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  cursor: pointer;
}

:root {
  --background-color: #f2f2f2;
  --background-color1: #eeeeee;
  --background-color2: #0027601a;
  --background-color3: #fff;
  --bgcolor: #fbfcfc;
  --searchbg: #F2F2F2;
  --text-color: #171725;
  --text-color1: #3f3f3f;
  --text-color2: #002760;
  --heading-color: #202020;
  --link-color: #00419f;
  --buttonBase: #00419f;
  --buttonBase1: #002760cc;
  --link-color: #00419f;
  --gray-blue: #00276040;
  --buttonHover: #002760;
  --white: #fff;
  --toggle: #00276040;
  --gradient-blue: linear-gradient(90.22deg, #002760 28.58%, #0062ff 100%);
  --gradient-gray: linear-gradient(180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(0, 39, 96, 0.8) 100%);
  --card-gradient: linear-gradient(180deg,
      rgba(0, 39, 96, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%);
  --Modalborder: #f1f1f5;
  --border: #dcdbdd;
  --label: #84818a;
  --gray: #92929d;
  --red: #b50000;
  --red1: #b5000040;
  --redText: #b50000;
  --yellow: #eed60040;
  --yellowText: #a39200;
  --background-color-light: #f2f2f2;
  --background-color-dark: #000000;
  --dark-blue-color: #002764;
  --card-text-color: #191919;
  --card-shadow: #dbd3d3;
  /* --card-shadow: #dbd3d3; */
  --badge-gray-bg: #e9e9ed;
  --badge-gray-text: #696974;
  --card-bg-color: #f6f6f6;
  --gredient-product-card: linear-gradient(to right, #002760, #0367d0);
  --text-color4: #002760;
  --border-color: #f1f1f5;
  --table-color: #fafafa;
  --table-bg-color: #ffff;
  --message-card-bg-color: #f2f7ff;
  --message-grey-color: #44444f;
  --text-grey-color: #6e6b7b;
  --button-navy-color: #e1ecff;
  --navy-color: #0062ff;
  --card-color: #ffff;
  --resume-color: #ffff;
  --resume-text-bg-color: #002760;
  --unread-notifications: #e3f1fe;
  --shimmer-gradient: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);

  --mainBg: #F2F2F2;
  --darkBG: #fdfdfd;
  --mainTextColor: #000;
  --disabled: #bcbcbc !important;
}

[data-theme="dark"] {
  --disabled: #b6b4b43d !important;
  --mainTextColor: #fff;
  --darkBG: #0f0f0f;
  --mainBg: #1a1a1e;
  --shimmer-gradient: linear-gradient(to right, #2b2b2b 8%, #1f1f1f 18%, #2b2b2b 33%);
  --background-color: #000;
  --background-color1: #1c1c24;
  --background-color2: #292932;
  --background-color3: #1c1c24;
  --searchbg: #1a1a1e;
  --bgcolor: #1c1c24;
  --text-color: #b5b5be;
  --text-color1: #b5b5be;
  --text-color2: #fff;
  --text-color4: #b5b5be;
  --heading-color: #fff;
  --link-color: #1e75ff;
  --buttonBase: #1e75ff;
  --buttonBase1: #002760cc;
  --buttonHover: #002760;
  --gray-blue: #175cc040;
  --toggle: #292932;
  --border: #3c3b3d;
  --dark-blue-color: #fff;
  --card-text-color: #ffff;
  --card-shadow: #0000;
  --card-bg-color: #1c1c24;
  --gray: #92929d;
  --border-color: #5b5b5b;
  --table-color: #fafafa;
  --table-bg-color: #292932;
  --message-card-bg-color: #2b2b31;
  --message-grey-color: #e8e8e8;
  --button-navy-color: #5b5b5b;
  --background-color-light: red;
  --card-color: #2d2d2d;
  --resume-color: #b6b6b6;
  --resume-text-bg-color: #cfcfcf;
  --background-color-dark: #000000;
  --unread-notifications: #4f5152;
  --badge-gray-bg: #696974;
  --badge-gray-text: #e9e9ed;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--background-color-light); */
  transition: background-color 250ms ease-in-out;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  color: var(--heading-color);
  font-weight: 500;
  margin-bottom: 0 !important;
  font-family: "Poppins-Regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: "Poppins-Regular";
}

span {
  color: var(--label);
  font-family: "Poppins-Regular";
}

.label {
  color: var(--label);
}

.text-danger {
  color: red;
}

.error_msg_lbl {
  color: red;
  font-size: 12px;
}

a {
  color: var(--link-color) !important;
  text-decoration: none !important;
  font-family: "Poppins-Regular";
}

li {
  list-style: none;
  font-family: "Poppins-Regular";
}

button:disabled {
  opacity: 0.5;
}

/* .container-fluid {
  padding: 0 !important;
} */

.form-error {
  font-size: 14px;
}

.f-14 {
  font-size: 14px;
}

.f-13 {
  font-size: 13px;
}

.f-400 {
  font-weight: 400;
}

.f-700 {
  font-weight: 700;
}

.f-600 {
  font-weight: 600;
}

.f-8 {
  font-size: 8px;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-28 {
  font-size: 28px;
}

.f-45 {
  font-size: 45px;
}

.app {
  transition: background-color 250ms ease-in-out;
}

/* button */
.mainBtn {
  background-color: var(--buttonBase);
  color: var(--white);
  border: none;
  padding: 12px 58px;
}

.compBtn {
  padding: 12px 28px;
}

.compBtn .material-symbols-sharp {
  color: var(--white) !important;
}

.error_msg_lbl {
  color: var(--red);
}

.mainBtn:hover {
  background-color: var(--buttonHover);
}

.mainBtn4 {
  background-color: var(--badge-gray-bg);
  color: var(--badge-gray-text);
  border: none;
  padding: 4px 70px;
  font-size: 12px;
  font-weight: bold;
}

.mainBtn4:hover {
  background-color: var(--gray);
  color: var(--text-color);
}

.mainBtn1 {
  background-color: var(--buttonBase);
  color: var(--white);
  border: none;
  width: 134px;
  height: 40px !important;
  font-size: 15px !important;

}

.mainBtn-accept {
  height: 40px !important;
}

.mainBtn1:hover {
  background-color: var(--buttonHover);
  /* color: var(--white);
  border: none;
  padding: 12px 58px; */
}

.mainBtn span {
  font-weight: bold;
  color: var(--white);
}

.mainbtn3 {
  background-color: transparent;
  color: var(--text-color2);
  border: 1px solid var(--text-color2);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /* padding: 12px 58px; */
  height: 30px;
  width: 68px;
}

.mainbtn3:hover {
  background-color: var(--buttonBase);
  color: var(--white);
}

.mainbtn3 .material-symbols-sharp {
  color: var(--text-color2) !important;
}

.mainbtn3:hover .material-symbols-sharp {
  color: var(--white) !important;
}

/* Divider */
.divider {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--label);
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  background-color: var(--border);
  margin: 5px;
}

.full-height {
  height: 100vh;
}

.bg-cover {
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 10px;
  /* Set the background image using inline style or using CSS modules */
  background-image: url("./assets/Images/SignIn/wlj-login-banner.jpg");
  position: relative;
}

.imageData {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 0);
}

.linksHelp {
  position: absolute;
  bottom: 5%;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.linksHelp p {
  color: var(--white);
  font-size: 14px;
}

.linksHelp a {
  color: var(--white) !important;
  font-size: 14px !important;
}

.imageData p {
  color: var(--white);
  font-size: 25px;
}

.imageData a {
  color: var(--white) !important;
}

.form-container {
  background-color: var(--bgcolor);
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 8px;
}

.header {
  font-size: 36px;
  font-weight: 500;
}

.paraClass1 {
  font-size: 14px;
}

/* Form Styling */
.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group .focused label {
  top: 0;
  font-size: 12px;
  color: #333;
}

.csQOMO {
  height: 3em !important;
  margin-right: 20px !important;
}

.password-input .csQOMO {
  border-bottom: none !important;
}

.cDQAUE {
  background: var(--bgcolor);
}

.buiSXW {
  font-size: 0.8em !important;
}

.password-input {
  border-bottom: 1px solid var(--border);
  /* margin-right: 20px !important; */
}

.password-toggle svg {
  color: var(--label);
}

.socialBtn {
  width: 168px;
  height: 48px;
  border: 1px solid var(--border);
  color: var(--heading-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
}

.socialBtn p {
  font-size: 13px;
}

.signup {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checker p {
  color: var(--label);
  font-size: 12px;
}

.mainheader {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.rootLayout {
  margin-top: 67px;
}

.rootLayout .left,
.headerLayout .left {
  width: 20%;
}

.rootLayout .center,
.headerLayout .center {
  width: 60%;
}

.rootLayout .leftSidebarData {
  width: 80%;
}

.rootLayout .right,
.headerLayout .right {
  width: 20%;
}

.mainheader.headerLayout {
  display: block !important;
}

.headerLayout .search-container {
  width: 98%;
}

.material-symbols-sharp,
.material-symbols-sharp-filled {
  color: var(--text-color1) !important;
  font-size: 20px !important;
  margin-left: 0 !important;
}

.dropdown-notification a svg path {

  /* fill: var(--text-color1) !important; */

}

.desktopView,
.mainheader.desktopView {
  display: flex;
  /* border-bottom: 1px solid var(--border); */
}

.mobileView,
.mainheader.mobileView {
  display: none;
}

.ToggleClose {
  display: none;
}

.right .sideLinks {
  padding-left: 0px !important;
  width: 19% !important;
}

.right .sideLinks .hashtags {
  padding-left: 20px;
}

.sideLinks .search-container {
  padding-left: 20px;
  border-top: 1px solid var(--border);
  z-index: 2;
}

.modalBorder h6 {
  font-weight: bold;
}

.draftPost {
  width: 500px;
}

@media screen and (max-width: 720px) {
  .draftPost {
    width: 100%;
  }

  .desktopView,
  .mainheader.desktopView {
    display: none !important;
  }

  .mobileView,
  .mainheader.mobileView {
    display: flex;
  }

  .mainheader.mobileView {
    padding: 10px 20px;
  }

  /* .mainheader .search-container {
    width: 80%;
  } */
  .footer-nav .navLinks {
    margin-top: 0;
  }

  .footer-nav {
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #ebebeb;
    z-index: 1;
    padding: 12px 0;
  }

  .feedAdvertise {
    display: none;
  }

  .menu {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    background-color: var(--mainBg);
    /* padding: 20px; */
    transition: left 0.3s;
    z-index: 1;
  }

  .menu.open {
    left: 0;
    z-index: 99;
  }

  .ToggleClose {
    display: block;
  }

  .loginImage {
    display: none;
  }

  .row.rootLayout.mobileView .col-md-12 {
    padding-right: 2px !important;
  }

  .modal-dialog {
    padding: 0 30px;
  }

  .postAdd textarea {
    width: -webkit-fill-available;
  }
}

.ImgAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.ImgAvatar1 {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.ImgAvatar img,
.ImgAvatar1 img {
  width: 36px;
  height: 36px;
}

.border-custom {
  border-bottom: 1px solid var(--border);
}

.border-custom1 {
  border-bottom: 2px solid var(--border);
}

.bordertop-custom {
  border-top: 1px solid var(--border);
}

.modalBorder {
  border-bottom: 1px solid var(--border);
}

.modalBorder h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--mainTextColor);
  padding: 20px;

}

.modal-content {
  border-radius: 0;
}

/* custom dropdown */

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 80px;
  /* Set the width as needed */
}

.custom-dropdown .dropdown-header {
  cursor: pointer;
  padding-right: 20px;
  border-bottom: 1px solid var(--border);
  background-color: #f9f9f9;
  color: var(--label);
  font-size: 12px;
}

.custom-dropdown .dropdown-header .material-symbols-sharp {
  color: var(--label) !important;
}

.custom-dropdown .dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  border: 1px solid #ddd;
  z-index: 1;
  /* display: none; */
}

.custom-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #ddd;
}

.circle .wljSecondaryBtn {
  padding: 20px 5px;
}

.slick-prev {
  left: -20px !important;
  z-index: 1;
}

.slick-next {
  right: -20px !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: var(--mainTextColor) !important;
}

.image-slider-modal {
  margin: 10px 20px;
}

.modal {
  /* width: 100%; */
}

.fade {
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  background-color: #00419f80;
  backdrop-filter: blur(8px);
}


@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  background: var(--shimmer-gradient) !important;
  background-size: 1000px 100% !important;
  animation: shimmer 2.2s linear infinite forwards !important;
}

.shimmer-card {
  border-radius: 0 !important;
}

.shimmer-thumbnail {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}

.shimmer-card {
  background-color: var(--background-color3) !important;
}

.connectionFollower {
  margin-left: 10px !important;
}

.material-symbols-sharp.GuestMode {
  color: var(--disabled) !important;
}

.GuestMode {
  color: var(--disabled) !important;
}

.footerGuest {
  background-color: var(--darkBG);
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.modalHeader {
  font-size: 16px;
  color: var(--background-color3);
  font-weight: lighter;
}

.toast-header {
  background: rgb(214 210 210 / 85%) !important;
}

.toast-body {
  background: rgb(145 158 169 / 65%) !important;
  font-size: 16px;
}

/* .go2072408551 {
  background: rgb(145 158 169 / 65%) !important;
  font-size: 16px;
   color: black !important;
  border-radius: 0 !important;
} */
.form-control,
.form-control:focus {
  background-color: var(--darkBG);
  color: var(--mainTextColor) !important;
  /* border-bottom: transparent !important; */
}

.form-control::placeholder {
  color: var(--mainTextColor);
}

.dropdown-item a {
  width: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--background-color3) !important;
}

.dropdownTxt:hover {
  color: var(--text-color1) !important;
}

.floating-label-input input {
  background-color: transparent !important;
  color: var(--mainTextColor);
}

textarea {
  border: 1px solid var(--border);
}

.form-container input {
  background-color: var(--darkBG);
  color: var(--mainTextColor);
}

.dot {
  position: relative;
}

.dot::before {
  position: absolute;
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50px;
  background-color: var(--heading-color);
  top: 50%;
  transform: translate(0, -50%);
}

.UpdateProfile .material-symbols-sharp.locationIcon {
  font-size: 18px !important;
}

.UpdateProfile .material-symbols-sharp.locationIcon.expericenLocation {
  margin-left: 5px !important;
}


.setting-header {
  padding: .5rem;
}

.signUpModal {
  color: var(--heading-color);
}

.listLink .navLinks svg {
  color: var(--text-color1);
  width: 20px;
}

.listLink .active .navLinks svg {
  color: var(--text-color2);
}

.userLink span {
  color: var(--white);
  margin-left: 25px;
}


.form-control::placeholder {
  color: gray;
  opacity: 1;
}

.flatpickr-calendar .flatpickr-year {
  overflow: hidden;
  /* Prevent scrolling */
}

.flatpickr-calendar .flatpickr-year .flatpickr-year-wrapper {
  pointer-events: none;
  /* Disable interaction */
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-container {
  font-family: "Poppins-Regular" !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}



.jobdetail-sideLinks {
  position: sticky;
  /* width: 18%; */
  /* padding-bottom: 70px; */
  /* overflow-y: auto;  */
  /* overflow-x: hidden;  */
}

.jobdetail-sideLinks .jobdetail-left {
  overflow-y: auto;
  overflow-x: hidden;
}

.jobdetail-sideLinks .jobdetail-right {
  /* width: 50%; */
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden !important;
  white-space: normal;
}

.jobdetail-sideLinks .jobdetail-left {
  overflow-y: auto;
  overflow-x: hidden;
}

.jobdetail-sideLinks .jobdetail-right {
  /* width: 50%; */
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

}

.modal-open {
  overflow: hidden !important;
}


.dropdown .topmessages button {
  width: 100%;
}

.ql-editor.ql-blank:before {
  color: var(--label) !important;
}

.otp-input input {
  width: 2em !important;
  margin-right: 10px;
  height: 2em;
  border: 2px solid var(--border);
}

.borderSignUp {
  border: 1px solid var(--buttonBase);
}

.formBorder {
  border: 1px solid var(--border);
}

.SigninHeading {
  color: var(--label);
}

.input-group>.form-control:focus,
.input-group>.form-floating:focus-within,
.input-group>.form-select:focus {
  border: 1px solid var(--border);
}

.popover-body {
  background-color: var(--darkBG) !important;
  color: var(--mainTextColor);
}

.syncBtn {
  padding: 2px;
  display: flex;
  align-items: center;
}

.Text-mb-Create .form-control[type=file] {
  padding: 5px;
}

.dropdown .topmessages button {
  border: none !important;
}