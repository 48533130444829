@import '../../index.css';

.post-user-image {
    width: 40px;
    height: 40px;

}

.post-user-image img {
    width: 100%;
    border-radius: 50%;
}

.post-user-text {
    color: var(--gray);
}

.post-user-date-time {
    color: var(--gray);
}

.more-menu-icon .material-symbols-sharp {
    color: var(--gray) !important;
}

.diamond-icon .material-symbols-sharp {
    color: var(--gray) !important;
}

.custom-icon .material-symbols-sharp {
    font-size: 18px !important;
}

.write-message-content-cont {
    width: 80%;
    height: 40px;
    background-color: transparent;
    border: 0.5px solid grey;
}

.write-message-content-cont input {
    outline: none;
}

.user-postOnlyContent .addComment {
    position: unset;
    padding: 40px 10px;
}

.EmojiPickerReact,
.emoji-close {
    position: absolute !important;
    right: 0;
    top: 80px;
    z-index: 1;
}

.postAction .material-symbols-sharp,
.postAction .material-symbols-sharp-filled,
.postAction .material-symbols-outlined {
    color: var(--text-color2) !important;
    cursor: pointer;
    font-size: 24px !important;
    line-height: 1;
}

.likeActions,
.CommentlikeActions {
    position: relative;
}

.reactionsHolder {
    position: absolute;
    top: -35px;
    display: flex;
    /* justify-content: space-around; */
    background: var(--white);
    box-shadow: 3px 3px 30px rgba(0, 10, 10, 0.2);
    padding: 5px;
    /* border-radius: 60px; */
    width: fit-content;
}

.reactionsHolder img {
    margin-right: 8px;
    width: 30px;
}

.userCommentdetail {
    background-color: var(--mainBg);
    /* width: 100%; */
    padding: 10px;
}

.commentAction span {
    /* font-size: 1.2rem; */
    color: var(--text-color);
    font-weight: bold;
}

.commentLike {
    font-size: 12px;
    cursor: pointer;
    color: blue !important;
}

/* .commentLike .material-symbols-sharp {
    font-size: 12px !important;
    background: black;
    padding: 3px;
    border-radius: 50px;
    color: blue !important;

} */

.commentInteraction .dropdownList {
    display: block;
}

.commentAction span.unlikeComment {
    color: var(--buttonBase);
}

.commentInteraction .InteractionModal {
    background-color: var(--border);
}

.ReplyComment {
    position: relative;
}

.ReplyComment .EmojiPickerReact,
.ReplyComment .emoji-close {
    top: 35px !important;
}

.ReplyComment .commentArea {
    position: unset;
}

.ReplyComment .commentBox {
    border: 1px solid var(--border);
}

.featured-card {
    border: 1px solid var(--border);
}


.modal-image {
    max-height: 90vh;
    height: calc(100vh - 120px);
    /* Adjust the 120px based on your modal's header/footer height */
    width: auto;
    object-fit: contain;
    /* Adjust this based on your preference (contain, cover, etc.) */
}

.singlePostLink .modal-image {
    height: calc(100vh - 100px);
}

.postContent {
    margin-bottom: 10px !important;
    word-wrap: break-word;
}

.postContent a {
    text-decoration: none;
    /* Remove underline */
    /* color: black !important; */
}


.emoji-container {
    position: relative;
    display: inline-block;
    margin: 5px;
}

.emoji-name {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--white) !important;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal !important;
}

.emoji-container:hover .emoji-name {
    opacity: 1;
}

.addComment .postCommentWrapper {
    background: none;
    /* Reset background if needed */
    padding: 0;
    /* Adjust padding if needed */
    border-radius: 0;
    /* Reset border-radius if needed */
}

/* Like details css */

.like-details {
    color: rgb(13, 0, 255);
    /* border-bottom: 1px solid rgb(13, 0, 255); */
    line-height: 12px;
    cursor: pointer;
    ;
}

.reaction-cont {
    /* height: 500px;
    overflow-y: scroll; */
}

.post-like-details-cont {
    width: 100%;
    height: 85px;
    border-bottom: 1px solid #f0f0f0;
}

.like-image-cont {
    width: 50px;
    height: 50px;
}

.like-image-cont img {
    width: 100%;
    border-radius: 50%;
}

.liked-imoji {
    width: 20px;
    height: 20px;
}

.liked-imoji img {
    width: 100%;
}

.liked-user-name {
    color: var(--heading-color);
}

.liked-info p {
    color: #cecece;
}

.postAdd svg,
.postMenu svg {
    width: 20px;
}

.postAction svg {
    color: var(--text-color2);
}

.postMenu svg,
.featured-card svg,
.postAdd svg {
    color: var(--text-color);
}

.postAdd .css-b62m3t-container {
    border: none !important;
}

.postAdd .css-1ch4rtc-control {
    border-radius: 0 !important;
}

.postContentDiv {
    color: var(--heading-color);
}