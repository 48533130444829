@import '../../index.css';

:root {
    /* --color-blue: #002760; */
    --font-weight-400: 400;
}

.messageBtn {
    background-color: var(--background-color3);
    color: var(--text-color2);
    border: 1px solid var(--text-color2);
    /* border-radius: 9px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 1px 12px;
    font-family: "Poppins-Regular";
}

.messageBtn:hover {
    background-color: var(--buttonBase);
}

.messageBtn:hover .msgText h1,
.messageBtn:hover .material-symbols-sharp {
    color: var(--white) !important;

}

.msgText h1 {
    font-size: 11px;
    margin: 0;
    font-weight: 600;
    color: var(--text-color2);
}

.resume-section {
    padding: 10px;
}

.resume-cont {
    background-color: var(--darkBG);
    margin-bottom: 8px;
    padding: 12px 27px;
    /* border-radius: 8px; */
    /* margin-left: 10px; */
}

.userName h1 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: var(--text-color2);
}

.designation h1 {
    font-size: 14px;
    font-weight: var(--font-weight-400);
    color: #696974;
}

.locationText {
    font-size: 14px;
    font-weight: var(--font-weight-400);
    margin-left: 7px;
}

.followerText {
    font-size: 12px;
    font-weight: var(--font-weight-400);
}

.desctext {
    font-size: 10px;
    font-weight: var(--font-weight-400);
}

.image-cont {
    width: 86px;
    /* height: 86px; */
}

/* .msgText h1:hover {
    color: var(--white);
} */
.filterBtn {
    width: 186px;
    height: 70px;
}

.currentCompInput {
    height: 70px;
    width: 229px;
}

/* .material-symbols-sharp {
    color: var(--text-color2) !important;
} */
.currentCompCont {
    width: 229px;
    background-color: var(--background-color3);
    height: 70px;
    margin-right: 8px;
    /* border-radius: 10px; */
    /* margin-left: 20px; */
}

.currentPlacHolder h1 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.currentIcon {
    margin-right: 10px;
}

.currrentInput {
    width: 150px;
    height: 60px;
    border: none;
    outline: none;
    background-color: var(--background-color3);
}

.currrentInput::placeholder {
    color: #B5B5BE;
    font-size: 16px;
    font-weight: var(--font-weight-400);
}

.locationDropDown {
    width: 229px;
    height: 70px;
    border-radius: 10px;
    background-color: var(--white);
}

.locationDropDown {
    padding: 0px 0px 13px 24px;
}

#dropdown-basic-button {
    background-color: var(--white);
    border: none;
    color: #B5B5BE;
}

.location-cont {
    width: 229px;
    height: 70px;
    /* border-radius: 10px; */
    background-color: var(--background-color3);
    margin-right: 8px;
}

.css-13cymwt-control {
    background-color: var(--background-color3) !important;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

.custom-select .react-select__menu {
    left: auto;
    right: 0;
}

.css-azjwza-control {
    border: none !important;
}

.dropdownList {
    display: flex;
}

.messageBtn .material-symbols-sharp {
    color: var(--text-color2) !important;
}

/* .material-symbols-sharp:hover {
    color: var(--white) !important;
} */
.dropdown-menu.show {
    border: none;
    background-color: var(--border);

    /* margin-top: 70px; */
}

.css-13cymwt-control {
    border: none !important;
}

.connection-input::placeholder {
    color: rgb(206, 206, 206);
}

.dropdownTxt:hover {
    color: #202020;
}

.connection-tab {
    background-color: var(--background-color3);
}

.wljSecondaryBtn {
    background-color: var(--darkBG);
    border: 1px solid var(--buttonBase);
    color: var(--buttonBase);
    font-size: 12px;
    height: 25px;
}

.wljPrimaryBtn,
.dropdown .wljPrimaryBtn {
    background-color: var(--buttonBase);
    border: 1px solid var(--buttonBase);
    color: var(--white);
    font-size: 12px;
    height: 25px;
}

.wljPrimaryBtn:hover,
.dropdown .wljPrimaryBtn:hover {
    background-color: var(--buttonHover);
}

.wljSecondaryBtn:hover {
    background-color: var(--buttonBase);
    color: var(--white);
}

.connectionTab .nav-link {
    margin-right: 20px;
}

@media only screen and (max-width: 414px) {
    /* .resume-cont {
        margin-left: 10px;
    } */

    .currentCompCont {
        width: 185px;
        margin: auto;
        margin-bottom: 10px !important;
    }

    .location-cont {
        width: 185px;
        margin: auto;
        margin-bottom: 10px !important;
    }

    .filterBtn {
        margin: auto;
    }

    .adImage img {
        width: 100%;
    }

    /* .currentCompCont {
        width: 370px;
    }

    .location-cont {
        width: 370px;
    }

    .filterBtn {
        width: 370px;
    } */
    .connectionPage {
        margin: 10px;
    }

    .connectionPage .tabButton {
        display: block !important;
        padding-left: 1rem !important;
    }

    .connectionPage .connectionTab .nav-link {
        margin-right: 0px;
    }
}

.ConnectionIcons {
    font-size: 20px !important;
    color: var(--text-color4);
}

.location span,
.vectorUserImage span {
    margin-left: 5px !important;
}




/* image change css */


/*  Rollover image styles  */
/* .figure {
    position: relative;
    width: 360px; 
    max-width: 100%;
  }
  .figure img.Sirv.image-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: contain;
    opacity: 0;
    transition: opacity .2s;
  }
  .figure:hover img.Sirv.image-hover {
    opacity: 1;
  } */