@import "../../index.css";

.articles-main-container {
  width: 70%;
  margin: auto;
}
.articles-custom-card {
  width: 200px;
  height: 220px;
  border: 1px solid grey;
}
.plus-cont {
  width: 80px;
  height: 80px;
  background-color: #fff3d9;
  border-radius: 50%;
  padding: 20px;
}
.plus-cont .material-symbols-sharp {
  font-size: 40px !important;
  color: #ffc542 !important;
}
.gray-color {
  color: var(--gray);
}
.edit-article-btn button {
  border: none;
  font-size: 9px;
  padding: 4px 12px;
  background-color: var(--border-color);
  color: var(--heading-color);
}
.progress {
  height: 10px;
  border-radius: 0;
  width: 100%;
}
.trash-cont .material-symbols-sharp {
  color: red !important;
}
.setting .material-symbols-sharp {
  color: var(--gray) !important;
}
.articles-custom-card {
  background-color: var(--background-color3);
}
.articles-cards-main-text {
  color: var(--heading-color);
}
.circle-style {
  margin: 0;
}
.blogs-card-image {
  width: 170px;
  height: 100px;
}
.blogs-card-image img {
  width: 100%;
}
.blogs-card-btn-cont {
  display: flex;
  justify-content: space-between;
}
.blogs-user {
  background-color: #fc5a5a;
}
.share .material-symbols-sharp {
  color: #fc5a5a !important;
}

@media only screen and (max-width: 414px) {
  .articles-custom-card {
    width: 375px;
  }
  .articles-main-container {
    width: auto;
    margin: 0;
  }
  .row-gap-blogs-card {
    margin-bottom: 20px;
  }
  .articles-cards {
    align-items: center;
  }
}
