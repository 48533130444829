@import '../../index.css';

.editor-bg {
    background-color: var(--card-bg-color);
}

.topic-sub-text {
    color: var(--gray);
}

.dropdown {
    background-color: transparent;
}

.dropdown button {
    display: flex;
    align-items: center;
    width: 270px;
    background: transparent;
    color: var(--text-color);
    justify-content: space-between;
    border: 0.5px solid var(--border);
    background-color: var(--background-color3);
}

.dropdown button:hover {
    /* background-color: var(--gray); */
    outline: none;
}

.dropdown button::after {
    color: var(--gray);
}

.btnColor {
    font-size: 14px;
}

.dropdown-item {
    font-size: 14px;
    color: var(--gray) !important;
}

.topic-main-text {
    color: var(--heading-color);
}

/* Tags  */
.tag-main-text {
    color: var(--heading-color);
}

.tag-container {
    width: 100%;
    height: 120px;
    border: 0.5px solid var(--border);
    background-color: var(--background-color3);
    color: var(--text-color);
}

.custom-tag {
    width: auto;
    padding: 5px;
    height: 25px;
    border: 0.5px solid var(--border);

}

.custom-tag .material-symbols-sharp {
    color: var(--gray) !important;
}

.choose-btn button {
    border: 0.5px solid var(--navy-color);
    color: var(--navy-color);
    font-size: 10px;
    padding: 4px 10px;
    background-color: var(--card-bg-color);
}

.choose-btn h4 {
    color: var(--gray);
}

.cover-article-main-text {
    color: var(--heading-color);
}

/* Setting */

.setting-up-main-text {
    color: var(--heading-color);
}

.time {
    width: 90px;
    height: 25px;
    /* background: var( --button-navy-color); */
    border: 0.5px solid var(--navy-color);
    font-size: 10px;
    padding: 5px 7px;
    color: var(--navy-color);
    background-color: var(--card-bg-color)
}

.cancel-button button {
    border: none;
    color: var(--gray);
    padding: 8px 30px;
    background-color: #eaeaea;
}

.publishBtn button {
    background-color: #50B5FF;
    color: var(--message-card-bg-color);
}


/* Editor pan */
.editor-pan-container {
    width: 100%;
    height: auto;
    background-color: var(--background-color3);
}

.editor-user-profile {
    width: 40px;
    height: 20px;
    background-color: rgb(8, 255, 177);
}

.editor-content-header {
    color: var(--heading-color);
}

.draft-icon {
    width: 35px;
    height: 35px;
    background-color: var(--message-card-bg-color);
    border-radius: 50%;
}

.draft-icon .material-symbols-sharp {
    color: var(--badge-gray-text) !important;
}

.ql-toolbar.ql-snow {
    border-top: 0.2px solid rgb(230, 230, 230);
    border-bottom: 0.2px solid rgb(230, 230, 230);

}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
    border: none;
}

.link-text {
    color: var(--navy-color);
}

.editor-tex {
    color: var(--label);
}

.editorfilterBtn {
    display: none;
}



@media only screen and (max-width: 414px) {
    .editor-bg {
        display: none;
    }

    .editorfilterBtn {
        display: block;
        display: flex;
        justify-content: end;
    }

    .modal-content {
        background-color: var(--background-color3) !important;
        margin-right: 15px;
    }
}