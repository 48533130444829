.manageResume {
  margin-top: 72px;
  position: relative;
}

.resumeList li {
  color: var(--heading-color);
}

.CCVvisibility {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  margin-bottom: 15px;
}

.input-group-text {
  background-color: var(--background-color3);
  border: 1px solid var(--border);
  border-radius: unset !important;
}