@import '../../index.css';


.mainheader {
    background-color: var(--darkBG);
    display: flex;
    justify-content: space-between;
    padding: 12px;
    z-index: 2;
    height: 72px;
}

.mainheader .Logo {
    width: 120px;
}

.mainheader .Logo img {
    width: 100%;
}

.search-container {
    display: flex;
    align-items: center;
    background-color: var(--searchbg);
    /* Gray background color */
    /* border-radius: 5px; */
    padding: 5px;
    height: 45px;
    width: 35%;
    align-items: center;
}

.search-icon {
    margin-right: 5px;
}

.search-icon .material-symbols-sharp,
.search-input-container input {
    color: var(--gray) !important;
}

.search-input-container {
    position: relative;
    flex: 1;
    /* height: 67px; */
}

.search-input {
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    padding: 5px;
    font-size: 16px;
}

.suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--bgcolor);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
}

.suggestion-item {
    padding: 5px;
    cursor: pointer;
    padding-bottom: 10px;
}

.suggestion-item:hover {
    background-color: var(--border);
}

.notification {
    background: var(--toggle);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: relative;
    margin-right: 10px;
}

.notification img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.topnotification img {
    position: unset;
    transform: unset;
}

.topMessagePin img {
    position: unset;
    transform: unset;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    z-index: 1000;
}

.option {
    cursor: pointer;
    padding: 4px 8px;
}

.option:hover {
    background-color: #f0f0f0;
}

.search-container .material-symbols-sharp {
    font-size: 25px !important;
    color: var(--gray) !important;
}

.notification .material-icon {
    color: var(--gray-blue);
    /* color: #002760; */
}

@media only screen and (max-width: 414px) {
    .search-container {
        width: 69%;
    }

    .desktop-dropdown {
        display: none;
    }

}

@media only screen and (max-width: 720px) {
    .search-container {
        width: 70%;
    }

    .header-action {
        margin-left: 0;
    }
}

.guesBtn {
    width: 110px;
}

.user .dropdownList a,
.user .dropdownList {
    display: flex;
    align-items: center;
}

.subheader {
    margin-top: 80px;
    margin-left: 20px;
}

.notificationDropdown {
    background-color: var(--bgcolor) !important;
    /* background-color: var(--white) !important; */
}

.notificationDropdown .dropdown-menu-header,
.notificationDropdown .message-cards {
    background-color: transparent;
    border-bottom: 1px solid var(--border);
}

.notification-message span {
    color: var(--heading-color) !important;
    /* color: white; */
}

/* .notificationDropdown .dropdown-menu-header:not(:last-child),
.notificationDropdown .message-cards:not(:last-child) {
    border-bottom: 1px solid var(--border);
} */
.topmessages .message-cards {
    border-bottom: none;

}

.topmessages .MessageListCont {
    width: -webkit-fill-available;
}

.topmessages .activeMsg {
    /* background: white !important; */
}

.notificationDropdown .dropdown-menu-header .dropdown-item:hover,
.notificationDropdown .message-cards:hover {
    background-color: var(--border) !important;
}

.notificationDropdown .notification-message span {
    font-size: 14px !important;
}

.notificationDropdown .content-text span {
    font-size: 12px !important;
}

.notificationDropdown .notification-title {
    font-size: 22px;
}

.badge-notificationCount {
    background-color: var(--badge-gray-bg);
    color: var(--badge-gray-text);
    padding: 5px 10px;
    font-size: 12px;
}