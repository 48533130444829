@import '../../index.css';

.notification-main-container {
    margin-left: 20px;
    margin-top: 20px;
}

.notificationMainText h5 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 36px;
}

.custom-message-card {
    /* width: 715px; */
    height: 78px;
    background-color: var(--background-color3);
    /* border-radius: 10px; */
    margin-bottom: 10px;
}

.msgCircle {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: #FC5A5A;
}

.messageMainText h1 {
    font-size: 13px;
    font-weight: 600;
    line-height: 22.5px;
    margin: 0;
    color: var(--card-text-color);
}

.messageSubText h1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.41px;
    color: var(--gray);
}

.textStyle {
    font-size: 12px;
    font-weight: 400;
}

.customIcon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #e2e9ff;
}

.heartCont {
    background-color: #fbfbdb;
}

.messageIcon {
    background-color: #dfffdf;
}

.customIcon .material-symbols-outlined {
    font-size: 20px !important;
    color: blue !important;
}

.heartCont .material-symbols-outlined {
    color: #f4bc10 !important;
}

.messageIcon .material-symbols-outlined {
    font-size: 16px !important;
    color: #73ef73 !important;
}

.menuIcon .material-symbols-outlined {
    font-size: 20px !important;
    color: var(--gray) !important;
}


.notification-message span {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.content-text span {
    font-size: 14px;
}

.side-text {
    font-size: 12px;
}

.dropdown-notification {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unreadNotification {
    background-color: var(--unread-notifications);
}

.notifictaionBadge {
    position: absolute;
    left: -3px;
    top: -20px;
    font-size: 10px;
}

.noti-btn {
    width: 100% !important;
    height: 40px !important;
    justify-content: center !important;
}