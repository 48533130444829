.container-fluid {
    margin: auto;
}

.user-resume {
    width: 600px;
}
.user-resume .row{
width: 100%;
margin: auto;
}
.resume-header {
    width: 100%;
    /* height: 100px; */
    background: var(--buttonHover);
}

.user-resume-name {
    /* margin-right: 165px; */

}

.user-resume-name h4 {
    font-size: 25px;
    color: gray;
}

.user-resume-id h2 {
    width: 150px;
    height: 30px;
    background-color: var(--buttonHover);
    border-radius: 16px;
    color: #ffff;
}

.resume-profile-image {
    width: 50px;
    height: 50px;
}

.resume-profile-image img {
    /* width: 100%; */
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.weLinkImage img {
    border: none;
}

.resume-heading-cont {
    width: 220px;
    height: 30px;
    background-color: var(--buttonHover);
    /* border-radius: 10px; */
}

.resume-heading-cont p {
    color: #ffff;
}

.resume-heading-cont .material-symbols-sharp {
    color: #ffff !important;
    margin-right: 5px;
    font-size: 14px !important;
}

.empty-cont {
    width: 215px;
    height: 1px;
    background-color: var(--buttonHover);
    border: none;
}

.custom-content {
    color: var(--text-color2);
}

.resume-heading-cont .material-symbols-sharp {
    color: #ffff !important;
}

.summary-cont {
    width: 100%;
    height: 35px;
    background-color: var(--buttonHover);
    color: #ffff;
    border-radius: 10px;
}

.summary-cont .material-symbols-sharp {
    color: #ffff !important;
    margin-right: 5px;
}

.epmty-large {
    width: 95%;
}

.automation-text h2 {
    width: 200px;
    border-bottom: 1px solid var(--text-color2);
}

.resume-sub-cont {
    background-color: var(--background-color3);
}

.resume-bottom-section {
    background-color: var(--background-color3);
}

.compny-title {
    color: black;
}

.user-resume {
    margin: auto;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

hr {
    color: var(--gray);
}

.automation-text li {
    color: var(--heading-color);
}

.automation-text span {
    color: var(--heading-color);
}

.listIcon {
    list-style: disc !important;
    color: #162525;
    word-wrap: break-word;
}



/* @media print {
    body {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }

} */



.resumeHeader h3 {
    color: var(--white);
    font-size: 22px;
    font-weight: 600;
}

.resumeHeader h4 {
    color: var(--white);
    font-size: 16px;
    /* font-weight: 600; */
    position: relative;
}

.uid {
    position: relative;
    display: flex;
    align-items: center;
}

.activeIcon {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50px;
    left: 0;
}

.leftDetail {
    background-color: var(--border);
    height: inherit;
}


.resumeHeading p {
    color: var(--resume-text-bg-color);
    border-bottom: 1px solid var(--resume-text-bg-color);
    padding-bottom: 5px;
    font-weight: 600;
}

.emp-details {
    margin-top: 5px;
    font-weight: 600;
}


@media only screen and (max-width: 767px) {
    .user-resume {
        width: 100%;
    }

    .resume-header {
        padding: 10px !important;
    }

    .resume-profile-image {
        width: 40px;
        height: 40px;
    }

    .resumeHeader h3 {
        font-size: 18px;
    }

    .resumeHeader h4 {
        font-size: 12px;
    }
}


@media print {
    body {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .user-resume {
        width: 100%;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    @page {
        margin: 0;
    }
}