@import '../../assets/css/people.css';

/* .friend-main-container {
    margin-left: 20px;
} */

.friendsHeaderText h1 {
    font-size: 24px;
    font-weight: 600;
    color: var(--card-text-color);
}

.search {
    width: 40px;
    height: 40px;
    /* border-radius: 14px; */
    background-color: var(--background-color3);
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 3px solid var(--card-shadow) !important; */
    border: 0.1px solid var(--card-shadow);

}

.dropdownCont {
    width: 190px;
}

.followStyle {
    color: var(--heading-color);
    padding: 3px 15px;
}

.customCards {
    /* width: 350px; */
    height: 200px;
    /* border-radius: 16px; */
    background-color: var(--darkBG);
    /* position: relative; */
    border-bottom: 3px solid var(--card-shadow) !important;
}



.mainCardText h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
    color: var(--card-text-color);
}

.cardSubText h1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    color: #696974;

}

.material-symbols-sharp {
    color: white;
}

.bottomText h1 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.1px;
    color: #92929D;
    line-height: 26px;
}

.profileImage {
    width: 70px;
    height: 70px;

}

.cardImage {
    width: 100%;
    height: 73px;
    background: url('../Images/People/Mask\ group.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.cardImage1 {
    position: relative;
    width: 100%;
    height: 120px;
}

.cardImage1 img {
    display: block;
    width: 100%;
    height: 120px;
}

.cardImage1 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the color and opacity as needed */
    z-index: 0;
}

.digitalImage {
    width: 100%;
    height: 72px;
    background: url('../Images/People/Mask\ group1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.profileImage img {
    width: 100%;
}

/* .messageBtn {
    width: 70px;
    height: 25px;
    color: var(--text-color2);
}
.followText h1 {
    font-size: 12px;
    font-weight: 600;
    color: var(--text-color2);
} */
/* .followBtn:hover {
    color: #ffff !important;
} */
.followBtn button {
    background: white;
    color: var(--text-color2);
    border: none;
    border: 0.5px solid var(--text-color2);
    font-size: 13px;
    border-radius: 8px;
    height: 23px;

}

.followBtn button:hover {
    background-color: var(--text-color2);
    /* color: var(--text-color2); */
}

.customCards {}

.empty {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: absolute;
    top: 40px;
    left: 20px;
}

.img-text {
    position: absolute;
    top: 55px;
}

.empty img {
    width: 100%;
}

.headerSection {
    align-items: baseline;
}

.cardBottomContainer {
    margin-left: 80px;
}

@media only screen and (max-width: 414px) {
    .friendsHeaderText h1 {
        font-size: 14px;
    }

    .headerSection {
        align-items: baseline;
    }

    .frindsCardContainer {
        /* margin-left: 15px; */
    }

    .compnies-create-btn {
        justify-content: center !important;
    }

    .manageCompnies h1 {
        display: flex;
        justify-content: center;
    }

    .compnies-card {
        padding-left: 0;
    }
}

.button {
    width: 80px;
    height: 25px;
    border: 0.1px solid var(--text-color2);
    background: var(--background-color3);
    /* border-radius: 12px; */
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 1px;
}

.button span {
    color: var(--text-color2);
}

/* .button:hover span {
    color: var(--background-color3);
} */

/* .button:hover:before {
    content: "Unfollow";
} */

.button:hover {
    background-color: var(--buttonHover);
    color: var(--background-color3);
    border: 0.5px solid black;
}

.error-cont {
    width: 60%;
    margin: auto;
}

.cardImage1 {
    height: 120px;
}

.newCardContent h1 {
    color: var(--white);
}

.company-followBtn {
    width: 95px !important;
    margin: 0;
}

.follower-button {
    width: 110px;
}


.PeopleCard {
    background-color: var(--darkBG);
    border-bottom: 3px solid var(--card-shadow) !important;
    margin: 10px 0;

}

.PeopleCardHeader {
    position: relative;
}

.centerAlignment {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translate(10px, -50%);
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the color and opacity as needed */
    z-index: 0;
}

.mainCardText h1 {
    color: var(--white);
    margin-left: 5px;
}

/* My compnies css */
.createCompBtn {
    width: 150px;
    font-size: 13px;
}

.createCompBtn span {
    color: #ffff !important;
}