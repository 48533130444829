@import "../../index.css";


.css-b62m3t-container {
  border: 1px solid var(--border) !important;
}

.iconsDropdown .material-symbols-sharp {
  font-size: 15px !important;
  margin-left: 5px;
}

.profileCard,
.UpdateProfile,
.aboutMe {
  position: relative;
  background-color: var(--background-color3);
  border-radius: 0;
}



.profileImg .material-symbols-sharp,
.Profilebackground .material-symbols-sharp {
  color: var(--white) !important;
}

.gradientBtn {
  background: transparent;
  border: 3px solid var(--text-color2);
  color: var(--text-color2);
  font-size: 14px;
  padding: 4px;
  height: 44px;
}

.profileHeader {
  margin: 20px 0 0 130px;
}

.profileDetails {
  position: absolute;
  bottom: 33%;
  left: 150px;
  color: var(--white);
  z-index: 1;
}

.profileDetails h4 {
  color: var(--white);
  font-size: 24px;
}

.profileDetails a,
.profileDetails p {
  color: var(--white) !important;
  font-size: 14px;
}

.badge-vip {
  font-size: 10px;
  background-color: var(--white);
  color: #000;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 5px 10px;
}

.badge-vip .material-symbols-sharp {
  margin-left: 5px !important;
  color: #ccd000 !important;
}

.tabName {
  color: var(--gray);
  margin-bottom: 8px !important;
}

.tabCount {
  color: var(--text-color1);
  font-weight: 700;
  margin-bottom: 8px !important;
}

.activeTab {
  border-bottom: 3px solid var(--text-color2);
  border-radius: 15px 15px 0 0;
}

.aboutTab.activeTab {
  border-bottom: none;
}

.activeTab p {
  color: var(--text-color2);
  font-weight: bold;
}

.progress {
  height: 6px;
  border-radius: 0;
  width: 60%;
}

.progress-bar {
  background-color: var(--buttonHover);
}

.UpdateProfile .check {
  font-size: 14px !important;
  color: green !important;
}

.UpdateProfile .circle .material-icon {
  font-size: 14px !important;
  color: var(--text-color);
}

.personalDetails {
  display: flex;
  margin: 20px 0;
}

.personalDetails .material-symbols-sharp {
  color: var(--gray) !important;
  margin-right: 10px;
}

.photoGallery {
  padding: 10px;
}

.photoGallery img {
  width: -webkit-fill-available;
  height: 100%;
  object-fit: cover;
}

.profileHeader .material-symbols-sharp {
  font-size: 26px !important;
  color: var(--gradient-blue) !important;
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.qrImage {
  position: relative;
}

.qrLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard .qrcode,
.dashboard .download {
  width: -webkit-fill-available;
}

.dashboard-card {
  background-color: var(--background-color1);
  padding: 8px;
}

.aboutMe .css-13cymwt-control {
  border-bottom: 1px solid var(--border) !important;
  border-radius: 0 !important;
}

.skillModal .css-13cymwt-control {
  border: 1px solid var(--border) !important;
}

.UpdateProfile .material-symbols-sharp {
  font-size: 22px !important;
}

.updateActions .material-symbols-sharp {
  font-size: 18px !important;
}

.lableStyle label {
  color: var(--gray);
}

.unFollowBtn .button {
  width: 134px;
  height: 40px;
}

.button {
  background-color: var(--buttonBase);
  border: none;
  width: 110px;
  height: 30px;
  color: red !important;
}

.button span {
  color: var(--white);
  font-size: 16px;
}

.button .hover-text,
.appliedBtn .hover-text {
  display: none;
}

.unFollowBtn .button {
  width: 150px;
}

.unFollowBtn button {
  transition: width 0.1s ease-in-out;
}

.button:hover .default-text,
.appliedBtn:hover .default-text {
  display: none;
}

.followStyle .default-text {
  color: var(--text-color2);
}

.button:hover .hover-text,
.appliedBtn:hover .hover-text {
  display: flex;
}

.followStyle {
  background-color: transparent;
  border: 1px solid var(--text-color2);
}

.followBtn {
  border: 3px solid var(--text-color2);
}

.cbtn {
  width: 150px;
}

.wljSecondaryBtn .material-symbols-sharp {
  color: var(--buttonBase) !important;
}

.wljSecondaryBtn:hover .material-symbols-sharp {
  color: var(--white) !important;
}

@media screen and (max-width: 720px) {
  .profileImg {
    width: 80px;
    height: 80px;
    border: 5px solid var(--white);
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
  }

  .vipId {
    display: block !important;
  }

  .badge-vip {
    margin-left: 0;
  }

  .profileHeader {
    margin: 10px;
  }

  .profileHeader .tabButton {
    margin-left: 0 !important;
  }

  .friend-main-container .row {
    width: 100%;
    margin: auto;
  }

  .business-main-container .row {
    width: 100%;
  }
}

.restOfSkills button {
  width: 100%;
  height: 40px;
  /* border: 0.3px solid grey; */
  font-size: 13px;
}

.restOfSkills button span {
  color: var(--heading-color);
}

.empty-container {
  width: 100%;
  height: 40px;
  background-color: var(--bgcolor);
  /* border-radius: 0px 0px 10px 10px; */
}

.empty-container button {
  border: none;
  background-color: var(--bgcolor);
  font-size: 13px;
}

.skillsBorder {
  border: 0.5px solid var(--gray);
}

.profileImg {
  width: 120px;
  height: 120px;
  border: 5px solid var(--white);
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 1;
}

.profileImg img {
  width: -webkit-fill-available;
  /* width: 100%; */
  border-radius: 50%;
  /* height: 110px; */
}

.profileImg {
  /* position: relative;
    width: 150px;
    height: 150px; */
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profileImg .background-edit {
  /* background-color: transparent !important; */
}

.background-edit {
  position: absolute;
  bottom: -5px;
  left: 70px;
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
}

.background-edit span {
  font-size: 24px;
  /* adjust based on your needs */
  /* color: white; */
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.background-edit .material-symbols-sharp {
  color: gray !important;
}

.profileImg:hover .background-edit {
  display: block;
  width: 35px;
  height: 35px;
  background-color: #ffff;
  border-radius: 50%;
}

.Profilebackground img {
  width: -webkit-fill-available;
}

/* .Profilebackground {
    position: relative;
    width: 150px;
    height: 150px; 
  }
  
  .Profilebackground img {
    width: 100%;
    height: 100%;
    border-radius: 50%; 
  } */

.backgroundimg-edit {
  position: absolute;
  top: 10px;
  /* adjust based on your needs */
  right: 10px;
  /* adjust based on your needs */
  display: none;
  background: transparent;
  cursor: pointer;
}

.backgroundimg-edit span {
  font-size: 24px;
  /* adjust based on your needs */
  color: white;
}

.Profilebackground:hover .backgroundimg-edit {
  display: block;
}

.edit-hover-cont {
  color: black;
}


.updateBtn button {
  width: 160px;
}

.profileHeader .tabButton a {
  margin-right: 12px;
}

.about-textArea textarea {
  border: 1px solid black;
  width: 100%;
}

.web-text {
  color: var(--heading-color);
}

.web-input input {
  background-color: var(--background-color3);
  color: var(--text-color);
  border: 1px solid var(--border) !important;
}

.settings .web-input input {
  border: 1px solid var(--border) !important;
}

.react-select__option--is-focused {
  background-color: lightgray;
  /* Change to your desired focus background color */
}

.react-select__option--is-selected {
  background-color: var(--background-color1) !important;
  /* Change to your desired selected background color */
  color: var(--background-color1);
  /* Change to your desired selected text color */
}



.Profilebackground {
  position: relative;
}

.background-overlay {
  position: relative;
}

.background-overlay::before {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.50);
  z-index: 1;
  position: absolute;
}

.background-overlay img {
  display: block;
  width: 100%;
  height: 200px;
  /* Ensure this matches your image height */
  object-fit: cover;
  position: relative;
  z-index: 0;
}

.backgroundimg-edit {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  z-index: 2;
}

/* 
.modal {
  z-index: 2 !important;
} */

.verified .material-symbols-sharp {
  font-size: 18px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.cropper-wrapper .cropper-view-box,
.cropper-wrapper .cropper-face {
  border-radius: 50%;
}

.cropper-bg-cont .cropper-wrapper .cropper-view-box,
.cropper-wrapper .cropper-face {
  border-radius: 0% !important;
}

.cropper-wrapper .cropper-face {
  border: none;
}

.background-overlay {
  cursor: pointer;
}

.profileImg {
  cursor: pointer;
}

.backrgound-profile-view {
  width: 100%;
  height: 100%;
}

.backrgound-profile-view img {
  width: 90%;
}

.modal.postAdd .modal-dialog {
  width: 100%;
}

.profile-view {
  width: 100%;
  /* height: 400px; */
}

.profile-view img {
  width: 100%;
}

.delete-icon {
  color: rgba(var(--bs-danger-rgb)) !important;
}

.background-edit-bg {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 45px;
  height: 45px;
  background: white;
  padding: 10px;
  border-radius: 50px;
  color: gray;

  /* .form-control {
    position: absolute;
    top: 0;
  } */
}

/* css for bg crop  */

.profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2;
}

.profile-img-container .profile-img {
  /* height: 8.92rem; */
  /* width: 8.92rem; */
  /* border: 0.357rem solid white;
  background-color: white; */
}

.profile-crop-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.profile-header .card-img-top {
  aspect-ratio: 36/10;
}

.cropperContianer {
  position: relative;
  height: 400px;
  /* width: '100%'; */
}

.crop-container {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
}



/* Testing cropper css */

.imageCard {
  text-align: center;
}

.imageCard img {
  width: 300px;
}

.backdrop {
  position: fixed;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 80px;
}

.controls-upper-area {
  text-align: center;
}

.slider {
  width: 50%;
}

.button-area {
  text-align: center;
  margin-top: 20px;
}

/* button {
  margin-left: 10px;
  margin-right: 10px;
  background-color: black;
  color: white;
  border: 1px solid yellow;
  font-size: 20px;
} */
.skills-more-cont {
  color: blue;
}

.skills-more-cont:hover {
  border-bottom: 1px solid blue;
  line-height: 18px;
}

.skills-container-fluid {
  background-color: transparent;
  overflow: none;
}

.connectionButton .cbtn {
  font-size: 14px;
}

.educcation-btn {
  font-size: 10px;
}

@media screen and (max-width: 720px) {
  .profileImg {
    left: 50%;
    top: 50px;
  }

  .background-overlay::before {
    /* width: 95%; */
  }

  .sub-content-container .row {
    margin: auto;
  }

  .ProfileInner {
    margin: 10px;
  }

  .ProfileInner .row,
  .ProfileInner .profileCard {
    width: 100%;
  }

  .userprofileactions {
    flex-wrap: wrap;
    /* margin-left: 20px; */
  }

  .userprofileactions .unFollowBtn,
  .userprofileactions .postMenu {
    margin-top: 10px;
  }

  .userprofileactions .postMenu {
    margin-left: 1rem !important;
  }
}

.uploadImage-cont {
  position: relative;
}

.dateofbirth .form-control {
  border: 1px solid var(--border) !important;
  padding: 10px;
}

.picUpload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(10px, 10px);
}

.metaImage .background-edit {
  left: 50px;
}

.metaImage:hover .background-edit {
  display: block;
  width: 25px;
  height: 25px;
  background-color: #ffff;
  border-radius: 50%;
}

.metaImage .background-edit .material-symbols-sharp {
  font-size: 16px !important;
}

.UpdateProfile .disbaled .material-symbols-sharp,
.UpdateProfile .disbaled p {
  color: var(--label) !important;
}

.reumeModal svg {
  font-size: 60px;
  color: green;
}

.disableText p,
.disableText .locationIcon,
.disableText h6 {
  color: var(--disabled) !important;
}

.disableText .dot::before {
  background-color: var(--disabled) !important;
}