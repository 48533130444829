/* .container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
  } */

.signInStyle button {
  background-color: #796eff;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  overflow: hidden;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms, color 150ms ease-in-out;
  white-space: nowrap;
  width: 100%;
}

/* Text */
.signInStyle button ul {
  float: left;
  height: 50px;
  margin: 0;
  padding: 0;
  transition: transform 300ms cubic-bezier(0, .65, .70, .90);
  transform-style: preserve-3d;
}

.signInStyle button li {
  --rotateX: 0deg;
  /* backface-visibility: hidden; */
  display: block;
  transition: opacity 150ms;
  transform-origin: 50% 50%;
  transform: rotateX(var(--rotateX)) translateZ(10px);
  width: 100%;
}

.signInStyle button li:nth-child(2),
.signInStyle button li:nth-child(3) {
  opacity: 0;
  position: absolute;
}

.signInStyle button li:nth-child(2) {
  --rotateX: -90deg;
}

.signInStyle button li:nth-child(3) {
  --rotateX: -180deg;
}

/* Icons */
.signInStyle button span {
  align-items: center;
  display: flex;
  float: left;
  height: 100%;
  justify-content: center;
  max-width: 0;
  /* opacity: 0; */
  position: relative;
  transform: translateX(-100%);
  transition: max-width 50ms ease, opacity 100ms ease, transform 150ms ease-in-out;
  width: 20px;
}

.signInStyle button .circle-style svg {
  position: absolute;
}

.signInStyle button .circle-style .circle-style svg:nth-child(1) {
  --stroke: 40;
  fill: transparent;
  height: 20px;
  left: 0;
  stroke: white;
  stroke-dasharray: var(--stroke);
  stroke-dashoffset: calc(var(--stroke) * 2);
  top: 15px;
  width: 20px;
}

.signInStyle button .circle-style svg:nth-child(2) {
  --stroke: 16;
  fill: transparent;
  height: 16px;
  left: 50%;
  stroke: transparent;
  stroke-dasharray: var(--stroke);
  stroke-dashoffset: var(--stroke);
  top: 25px;
  transform: translate3d(-5px, -3px, 0);
  transition: stroke 300ms;
  width: 16px;
}

/* States */
.signInStyle button.-request,
.signInStyle button.-success {
  text-align: left;
  pointer-events: none;
}

.signInStyle button.-request {
  background-color: #635ac7;
}

.signInStyle button.-request .circle-style svg:nth-child(1) {
  animation: stroke 2s linear infinite forwards, rotate 1s linear infinite forwards;
}

.signInStyle button.-request ul {
  transform: rotateX(90deg);
}

.signInStyle button.-request li:nth-child(2) {
  opacity: 1;
  position: relative;
}

.signInStyle button.-success {
  background-color: #00bf9c;
}

.signInStyle button.-success .circle-style svg:nth-child(1) {
  fill: #fff;
  stroke-dasharray: 0;
}

.signInStyle button.-success .circle-style svg:nth-child(2) {
  animation: stroke 300ms ease-in-out forwards;
  stroke: #00bf9c;
}

.signInStyle button.-success ul {
  transform: rotateX(180deg);
}

.signInStyle button.-success li:nth-child(3) {
  opacity: 1;
  position: relative;
}

.custom-input {
  background-color: red !important;
}




/* Animations */
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.signInStyle {
  width: 150px;
}

.signInStyledButton {
  border: none;
  background-color: transparent;
}

.signInStyle button {
  display: flex;
  justify-content: center;
}

.loaderStyle {
  background-color: yellow;
  color: black;
}

.border-signIn {
  border-bottom: none !important;
}

.userLink .circle-container {
  position: unset;
  display: flex;
  margin: auto;
}

.userLink .circle-container img {
  position: unset;
}