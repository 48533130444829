.linkSearch .material-symbols-sharp {
    color: var(--buttonBase) !important;
}

.SingleJob img {
    width: -webkit-fill-available;
}

.lead-product-text,
.feature-card-main-text {
    color: var(--heading-color);
}

.single-job-bottom-cont .mainBtn4 {
    display: none;
}

.single-job-bottom-cont .mainBtn {
    padding: 10px 30px;
}