.intro-section {
    width: 100%;
    height: auto;
    background-image: url(../Images/Privacy/background.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #ffff;
}

.content-color {
    color: var(--buttonHover);
}

.contact-icon {
    color: var(--buttonHover);
}

.policyHeading {
    margin-top: 25%;
}

.vision-para {
    width: 180px;
}

.content-container {
    width: 700px;
    margin-left: 100px;
    padding-bottom: 100px;
}

.vision-style {
    margin-right: 200px;
}

.privacy-para {
    width: 700px;
}

/* Privacy policy */

.privacy-policy-main-cont {
    margin-left: 80px;
}

/* Terms and Condition */

.terms-condition-cont {
    margin-left: 80px;
    margin-top: 25%;
}

.italic-font {
    font-style: italic;
}

.terms-condition li {
    margin-bottom: 10px;
}

.user-agreement {
    margin-left: 80px;
    margin-top: 25%;
}

.contact-icon .material-symbols-sharp {
    font-size: 35px !important;
    color: var(--text-color2) !important;
}

.contact-us-content .content-color {
    margin-bottom: 50px;
}

.contact-form-cont {
    width: 400px;
}

.privacy-contact-cont {
    margin-left: 80px;
    margin-top: 25%;
}

.message-input {
    padding-bottom: 70px;
}

.about-main {
    color: var(--buttonHover)
}