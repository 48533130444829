@import "../../index.css";

/* .job-notification-main-container {
    padding-top: 67px;
} */
.job-notification-card {
  width: 100%;
  height: 70px;
  /* background-color: #fafafa; */
  border-bottom: 2.5px solid #f1efef;
}

.job-offers-btn .job-custom-btn button {
  width: 100px;
  height: 25px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  color: grey;

}

.border-style {
  border: none !important;
}

.profile-btn {
  color: #fff !important;
  background-color: var(--text-color2) !important;
}

.card-image {
  width: 42px;
  height: 42px;
}

/* .card-image img {
  width: 100%;
} */

.recent-icon {
  width: 36px;
  height: 36px;
}

.recent-icon img {
  width: 100%;
}

.card-text {
  width: 135px;
  color: var(--text-color);
}

.notification-sub-text {
  color: var(--grey);
}

.locationText {
  text-wrap: balance;
}

.dropdown-menu-cont {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notify-custom-text .material-symbols-sharp {
  margin-right: 5px;
  color: var(--gray) !important;
}

.notification-table .dropdown {
  /* display: none; */
  background-color: transparent;
}

.notification-table tr {
  border-bottom: 2px solid var(--border);
  background-color: red !important;
}

.notification-table td {
  background-color: var(--background-color);
  padding: 10px 5px;
}

.table-row {
  background-color: red;
}

.transaction-table {
  padding: 10px;
  background-color: var(--mainBg);
}

.table-header th {
  color: var(--gray);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: var(--table-bg-color);

}

.row-gap {
  margin-bottom: 10px !important;
}

.apply-on-text {
  color: var(--text-color);
}

.recent-text {
  color: var(--text-color);
}

.job-desc-text {
  color: var(--text-color);
}

.job-custom-btn .wljPrimaryBtn a {
  color: var(--white) !important;
}

.applicationReact {
  width: 140px !important;
  margin-right: 10px;
}


.badge-notification {
  background-color: var(--badge-gray-bg);
  color: var(--badge-gray-text);
  padding: 8px 25px;
  font-size: 14px;
}

.hired {
  background-color: #b1d4b1;
  color: green;
}

.rejected {
  /* --red: #b50000;
  --red1: #b5000040;
  --redText: #b50000; */
  background-color: var(--red1);
  color: var(--redText);
}

.hiringSelect {
  width: 150px !important;
  /* margin: auto; */
}

/* .badgeContainer {
  display: flex;
  justify-content: center;
} */

.remark {
  word-wrap: break-word;
  /* Break long words to prevent overflow */
  overflow-wrap: break-word;
  /* Prevents overflow for large unbreakable text */
  white-space: normal;
  /* Allows text to wrap normally */
}