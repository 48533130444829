@import '../../index.css';

.settingSidebar {
    background-color: var(--background-color3);
    /* padding: 20px; */
}

.settingSidebar .settingIcon {
    font-size: 28px !important;
}

.username p {
    color: var(--text-color1);
}

.username .innerHeading {
    color: var(--buttonBase);
    font-weight: 700;
}

.settingSidebar .active .navLinks span,
.securityactive span {
    font-weight: 700 !important;
    color: var(--text-color1) !important;
    font-size: 14px !important;
}

.securityactive svg {
    color: var(--heading-color);
}

.securityactive ul {
    padding-left: 1rem;
}

.setting-header {
    border-bottom: 1px solid var(--border);
}

.setting-header h4 {
    font-size: 14px;
    font-weight: 700;
    color: var(--text-color1);
    padding: 20px;
    margin-bottom: 0;
}

.selectdropdown .css-13cymwt-control {
    border: none !important;
    border-bottom: 1px solid var(--border) !important;
    border-radius: none !important;
}

.css-1u9des2-indicatorSeparator {
    background-color: transparent !important;
}

.css-tj5bde-Svg {
    color: black;
}

.dropdownIcon {
    color: var(--heading-color);
}

/* .dob label {
    top: 10px;
} */

.settings textarea,
.settings input,
.settings input:focus {
    border: none !important;
    border-bottom: 1px solid var(--border) !important;
    display: block;
    background: transparent !important;
    color: var(--heading-color) !important;
}

.settings label {
    font-size: 12px;
    color: var(--label);
}

.modal-p {
    padding: 10px 20px;
}


.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 5px;
    background-color: #007B05;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: white;
    border: 1px solid var(--gray);
}

input:focus+.slider {
    box-shadow: 0 0 1px #007B05;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: var(--gray);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    border: 1px solid #007B05;
}

.slider.round:before {
    border-radius: 50%;
}

.notificationTable th,
.notificationTable td {
    padding: 10px 10px 20px 10px;
    font-size: 14px;
    color: var(--heading-color);
}

ul.dr {
    padding-left: 10px;
}

.privacy p {
    font-size: 14px;
}

.btnDanger {
    background-color: var(--red);
    color: var(--white);
    width: 134px;
    height: 40px;
    font-size: 12px;
    border: none;
}

.sessionName {
    color: var(--gray);
    font-size: 12px !important;
    padding: 10px 0px;
}

.sessionTime {
    border-bottom: 1px solid var(--border);
    padding-bottom: 10px;

}

.sessionAction .material-symbols-sharp {
    color: var(--red) !important;
}

.sessionAction .edit {
    color: var(--link-color) !important;
}

.authentication {
    width: 200px;
}

.confirmationTag {
    background-color: var(--yellow);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.deactivate {
    color: var(--yellowText);
    background-color: var(--yellow);
    font-size: 12px;
    margin: 20px;
}

.deactivate span,
.deactivate .material-symbols-sharp {
    color: var(--yellowText) !important;
}

.closeAccount {
    color: var(--redText);
    background-color: var(--red1);
    font-size: 12px;
    margin: 20px;
}

.closeAccount span,
.closeAccount .material-symbols-sharp {
    color: var(--redText) !important;
}

.cardHeader {
    font-weight: 700;
}

.deactivate li,
.closeAccount li {
    list-style: disc !important;
}

.closebtn {
    width: 134px;
    height: 40px;
    background-color: var(--red1);
    color: var(--redText);
    border: none;
}

.deactivatebtn {
    width: 134px;
    height: 42px;
    background-color: var(--yellow);
    color: var(--yellowText);
    border: none;
}


.settingSidebar .image-cont {
    width: 60px;
    height: 60px;
}

.settingSidebar .image-cont img {
    width: 100%;
}

.blockbtn {
    width: 80px;
    height: 23px;
    color: var(--link-color);
    border: 1px solid var(--link-color);
    font-size: 12px;
    display: flex;
    align-items: center;
    background-color: white;
    font-weight: bold;
}

.blockbtn .material-symbols-sharp {
    color: var(--link-color) !important;
}

.blockbtn:hover {
    background-color: var(--link-color);
    color: white;
}

.blockbtn:hover .material-symbols-sharp {
    color: white !important;
}

.addAddress .material-symbols-sharp,
.addAddress span {
    color: var(--link-color) !important;
    font-size: 14px;
    font-weight: bold;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control {
    border-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--border) !important;
    border-radius: 0 !important;
}

.settingSidebar .css-13cymwt-control {
    border-bottom: 1px solid var(--border) !important;
    border-radius: 0 !important;
    background: transparent !important;
}

.css-1dimb5e-singleValue {
    color: var(--heading-color) !important;
}

/* .css-qbdosj-Input input {
    display: none !important;
} */

.modal-content {
    background-color: var(--background-color3);
}

.followStyle {
    border: 1px solid var(--text-color2) !important;
    color: var(--mainTextColor) !important;
}

.followStyle:hover {
    background-color: var(--buttonHover);
    color: var(--white) !important;
    border: 1px solid var(--buttonHover) !important;
}