@import "../../index.css";

.newBtn {
  padding: 0px 20px;
}

.newBtn .material-symbols-sharp {
  color: var(--table-color) !important;
}

.main-card {
  border-radius: 0;
  background-color: var(--background-color3);
}

.message-scroller {
  height: 88vh;
  overflow: scroll;
  padding-bottom: 250px;
}

.message-cards {
  width: 95%;
  height: 70px;
  margin: auto;
  background-color: var(--message-card-bg-color);
}

.darkmode {
  color: var(--heading-color);
}

.empty-msg-card {
  width: 40px;
  height: 40px;
  background-color: #fc5a5a;
  border-radius: 50%;
}

.activeMsg {
  color: var(--background-color3) f;
  background: var(--gredient-product-card);
}

.message-time {
  color: var(--gray);
}

.messageText {
  color: var(--gray);
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
  /* margin-right: 2px; */
}

/* ChatBox */

.chat-box-main-container {
  /* position: fixed !important; */
  /* top: 69px; */
  width: -webkit-fill-available;
  z-index: 99;
  display: block !important;
  background-color: var(--background-color3);
}

.online-text {
  color: var(--gray);
}

.chat-box-username {
  /* border-right: 0.5px solid var(--border-color); */
  color: var(--text-color);
}

.menu-list-icon .material-symbols-sharp {
  color: var(--gray) !important;
}

.horizontal-line {
  width: 40%;
  height: 1px;
  background: var(--border-color);
}

.message-box {
  color: var(--message-grey-color);
  background-color: var(--border);
  width: fit-content;
  /* height: 50px; */
  /* border: 0.5px solid var(--border-color); */
  padding: 12px 20px;
}

.today-text {
  color: var(--text-color);
}

.chat-empty {
  width: 30px;
  height: 30px;
}

.self-message {
  flex-direction: row-reverse;
  margin-bottom: 2rem !important;
}

.self-message-content {
  background-color: var(--unread-notifications);
}

.images-empty-cont {
  width: 150px;
  height: 150px;
  background-color: #fc5a5a;
}

.imagesChat {
  width: 250px;
}

.messageCardContent a {
  color: var(--card-text-color) !important;
}

.messageTextRight {
  width: 500px;
  padding: 10px;
  border: 0.5px solid var(--border-color);
}

.chat-sub-text {
  color: var(--heading-color);
}

.write-message-cont {
  width: 92%;
  height: 40px;
  border: none;
  border: 0.5px solid var(--border-color);
  padding: 0px 10px;
}

.write-message-cont input::placeholder {
  font-size: 10px;
}

.write-message-cont input {
  border: none;
  outline: none;
  background-color: var(--background-color3);
  color: var(--mainTextColor);
}

.icons-cont .material-symbols-sharp {
  font-size: 18px !important;
  color: var(--gray) !important;
}

.chat-main-content {
  height: 76vh;
  overflow: scroll;
  background-color: var(--background-color3);
  margin-top: 69px;
}

.activeMsg .message-user-name,
.activeMsg .message-time,
.activeMsg .messageText {
  color: var(--table-color);
}

.write-message-text {
  width: 100%;
  height: 50px;
  background-color: var(--background-color3);
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
  margin-left: 5px;
}

.main-card {
  position: fixed;
  width: 26%;
  border-radius: 0;
  border: none;
  border-right: 1px solid var(--border);
}

.menu-list-icon {
  display: flex;
}

.message-time-responsive {
  display: none;
  color: var(--gray);
}

.online-text {
  display: none;
  color: var(--gray);
}

.message-box .material-symbols-sharp {
  font-size: 12px !important;
  margin-left: 5px !important;
}

.message-box .text-read {
  color: var(--link-color) !important;
  font-size: 14px !important;
}

/* For Responsive  */

@media only screen and (max-width: 414px) {
  .message-time {
    display: none;
  }

  .message-time-responsive {
    display: block;
  }

  .message-box {
    height: 70px;
  }

  .online-text-responsive {
    display: block;
    color: var(--gray);
  }

  .messageTextRight {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .chat-box-main-container {
    padding: 10px !important;
  }

  .chat-main-content {
    padding-left: 10px !important;
  }

  .chat-images-cont {
    flex-direction: column;
    align-items: end;
  }

  .images-empty-cont {
    margin-bottom: 10px;
  }

  /* .mainheader .material-symbols-sharp {
  display: none;
 } */
  /* .friends-list .main-card {
    width: 100%;
    position: unset;
  } */

  /* .friends-list {
    display: none;
  } */


}



.messageBox img {
  padding: 0;
  width: 90%;
  margin: 10% 0 0 10%;
}

.messageEmoji .EmojiPickerReact {
  bottom: 40px;
  top: unset;
}

.messageEmoji .emoji-close {
  right: 10px;
  top: 140px;
}

.username-header {
  position: fixed;
  background: var(--background-color3);
  top: 69px;
  width: -webkit-fill-available;
  padding: 20px;
  z-index: 1;
  margin-left: 1px;
  border-bottom: 1px solid #eaeaea;
}

.chatInteraction .dropdown-menu {
  right: 0;
}

.selfChat .dropdown-menu {
  left: 0;
}

.dropdown-menu-media {
  width: 340px;
}

.no-border-radius {
  border-radius: 0 !important;
  border: none;
}

.no-border-radius .toast-header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.no-border-radius .toast-body {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.connection-card .form-control {
  border: 1px solid var(--border);
  border-bottom: 1px solid var(--border) !important;
}

@media screen and (max-width: 720px) {
  .main-card {
    position: relative;
    width: 100%;
  }

  .username-header {
    position: unset;
  }

  .message-wrap h4 {
    background-color: var(--background-color3);
    padding: 20px;
  }
}