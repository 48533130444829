@import "../../index.css";

.followers-container {
  /* width: 220px; */
  /* height: 202px; */
  /* border-radius: 10px 10px 0px 0px; */
  background-color: var(--card-bg-color);
  left: 0px;
  top: 135px;
  z-index: 1;
  width: 33%;
}

.coverImageCont {
  width: 100%;
  height: 150px;
}

.coverImageCont img {
  width: 100%;
}

.cardContainer {
  width: 100%;
  height: 90px;
  /* background-color: var(--card-bg-color); */
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
}

.followerTabs {
  /* width: 106%; */
  height: 60px;
  background-color: var(--background-color3);
  /* margin-top: 70px; */
}

.businessCardButton {
  /* width: 106%; */
  justify-content: center;
  height: 52px;
  /* background-color: var(--card-bg-color); */
}

.alertButton button {
  background-color: #002760;
  color: #fff;
  font-size: 9px;
  padding: 7px 10px;
  border: none;
}

.followButton button {
  /* padding: 0px 15px; */
  color: var(--badge-gray-text);
  /* display: flex; */
  align-items: center;
  font-size: 11px;
  height: 25px;
  width: 80px;
  background-color: #f1f1f5;
  border: none;
  font-weight: 700;
  /* padding: 5px 20px; */
}

.followButton .material-symbols-sharp {
  color: var(--badge-gray-text) !important;
}

.tabCountFollower {
  color: var(--text-color1);
  font-weight: 700;
  margin-bottom: 0;
}

.metaImage {
  width: 80px;
  height: 80px;
  left: 50%;
  transform: translate(-50%, 0px);
  top: -55px;
  z-index: 1;
  cursor: pointer;
}

.metaImage img {
  width: 100%;
}

.followerMainText h1 {
  width: 185px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  /* margin-left: 25px; */
  margin-bottom: 3px;
}

.metaText a {
  color: #cfcfcf !important;
  font-size: 11px;
}

.followerBadge-vip {
  font-size: 12px;
  background-color: var(--white);
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -moz-fit-content;
  width: 120px;
  height: 20px;
  margin-left: 10px;
  font-weight: 600;
  border-radius: 10px;
  padding: 0px 6px;
  box-shadow: 0px 2px 3px grey;
}

.followerBadge-vip .material-symbols-sharp {
  color: #ccd000 !important;
  margin-left: 5px !important;
  font-size: 15px !important;
}

.userProductContainer {
  /* margin-top: 70px; */
  /* bottom: -40px; */
  right: 0;
  position: absolute;
  padding-right: 0;
}

.followerText h1 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.followerText a {
  color: #0062ff !important;
}

.followersContainer {
  width: 230px;
  background-color: var(--card-bg-color);
}

.userImage {
  width: 35px;
  height: 35px;
  margin-right: 7px;
}

.userImage img {
  width: 100%;
}

.productCardData {
  /* width: 220px; */
  height: 110px;
  /* background-image: linear-gradient(to right, #002760, #0367D0); */
  background: var(--gredient-product-card);
}

.leadProductText h1 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #ffff;
}

.productUserLoaction h6 {
  font-size: 10px;
  font-weight: 400;
  color: #ffff;
}

.viewBtnStyle button {
  color: black;
  line-height: 13.8px;
  padding: 3px 8px;
}

.saveCard {
  width: 20px;
  height: 20px;
  /* background-color: lightblue; */
  border: 0.1px solid grey;
}

.saveImage {
  width: 8px;
  height: 8px;
}

.saveImage img {
  width: 100%;
  margin-bottom: 5px;
}

.priceHours h1 {
  font-size: 12px;
  font-weight: 700;
  color: #ffff;
}

.leftTime h1 {
  font-size: 10px;
  font-weight: 400;
  color: #ffff;
}

.leftTime {
  margin-top: 35px;
}

.webSubText p {
  color: var(--gray);
}

.UpdateProfile {
  background-color: var(--background-color3);
  margin-bottom: 5px;
}

.UpdateProfile hr {
  color: var(--gray);
  margin: 5px 0px;
}

.popularPostCont {
  width: 100%;
  height: auto;
  background-color: var(--background-color3);
  margin-top: 5px;
}

.subContent p {
  color: var(--badge-gray-text);
}

.postImage {
  width: 130px;
  height: 80px;
}

.postImage img {
  width: 100%;
}

.postDate h1 {
  color: var(--gray);
}

.seeAllText {
  text-align: center;
}

.aboutMe {
  margin-bottom: 20px;
}

.mediaImages {
  width: 70px;
  height: 70px;
}

.mediaImages img {
  width: 100%;
}

.media-image-style {
  width: 150px;
  height: 80px;
}

.mobile-view-cont {
  display: none;
}

.LocationModal.skillModal .css-13cymwt-control {
  border: 1px solid #767676 !important;
}

.LocationModal .mainBtn1,
.locationBtn {
  width: 200px;
}

.websiteCont {
  /* margin-top: 192px; */
  /* margin-left: 15px; */
  /* width: 106%; */
}

.companyData {
  margin-top: 70px;
}

.mobile-view-followers {
  /* justify-content: space-between; */
}

.review-sub {
  background-color: var(--background-color3);
  margin-left: 7px;
}

.reviewBtn {
  width: 90px;
  height: 30px;
  border: none;
  background-color: #002760;
}

.reviewImage {
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  border-radius: 50%;
}

.reviewImage img {
  width: 100%;
}

.review-user-name {
  color: var(--heading-color);
}

.review-data {
  color: var(--heading-color);
}

.text-content {
  word-break: break-all;
}

.companyPage.profileDetails {
  bottom: 12%;
}

.companyPage .material-symbols-sharp.mycompanyAction {
  color: var(--white) !important;
}

.profileCard {
  /* z-index: 2; */
}

.profileImg {
  /* margin-left: 12px; */
}

.followerTabs .tabButton a {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .followerTabs {
    display: block !important;
    height: 130px !important;
  }

  .sub-content-container .mobile-view,
  .mainProfileContainer .profileTabContent,
  .mainProfileContainer .newsfeedPosts.ad-container,
  .sub-content-container .userProductContainer {
    position: unset;
  }

  .userProductContainer {
    justify-content: center !important;
    margin-top: 0px;
    position: unset;
    padding-bottom: 50px;
    padding-left: 20px;
  }

  .mobile-view-followers {
    /* display: none; */
    justify-content: center;
  }

  .productCard {
    margin-top: 10px;
    /* display: none; */
  }

  .followers-container {
    /* display: none; */
    /* width: 370px; */
    margin-bottom: 10px;
    width: 90%;
    margin-left: 20px;
  }

  .coverImageCont img {
    /* width: 95%; */
  }

  .mobile-view-cont {
    display: none;
    margin-left: 12px;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .followersContainer {
    width: 100%;
    /* display: none; */
    margin-left: 20px;
  }

  .productCard.followersContainer {
    margin-left: 0;
  }

  .userImage {
    width: 60px;
    height: 60px;
  }

  .metaImage {
    width: 80px;
    height: 80px;
    left: 42%;
    top: -55px;
  }

  .productCardData {
    width: 100%;
  }

  .followerTabs {
    padding-top: 10px;
  }

  .popularPostCont {
    /* display: none; */
  }

  .followerTabs {
    margin-left: 12px;
  }

  .websiteCont {
    margin: 0;
    /* margin-left: 10px; */
    margin-left: 12px;
    /* margin-bottom: 342px; */
    /* margin-top: 200px; */
  }

  .feature-responsive {
    margin-top: 5px;
  }

  .coverImageCont {
    margin-left: 5px;
  }

  .websiteCont,
  .followerTabs {
    width: 95%;
  }
}

@media only screen and (max-width: 320px) {
  .postAction p {
    font-size: 10px;
  }
}


.coverImageCont {
  /* border: 1px solid var(--border); */
}

.businessCardButton .mainBtn1 {
  font-size: 12px;
}

.textReview {
  border: 1px solid var(--border) !important;
}

.businessCardButton .markBtn {
  font-size: 11px !important;
}