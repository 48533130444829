@import "../../index.css";

.blogs-main-heading {
  color: var(--card-text-color);
}

.find-course-btn button {
  padding: 5px 13px;
  background-color: var(--bg-white-grey-color);
}

.newBtn button {
  background-color: var(--buttonHover);
  color: var(--white);
}

.searchInput {
  /* height: 40px; */
}

.blogs-image {
  width: 280px;
}

.blogs-image img {
  width: 100%;
}

.blogs-tab {
  width: 100%;
  background-color: transparent;
}

.menu-horz {
  width: 30px;
  height: 30px;
  background: #e8e8e8;
}

.menu-horz img {
  width: 12px;
}

.menu-folder {
  background-color: #919191;
}

.search-dropdown {
  height: 30px;
  background-color: #e8e8e8;
}

.css-13cymwt-control {
  background-color: transparent !important;
  height: 30px !important;
}

/* .text-center {
  margin-right: 20px;
} */

.filter .material-symbols-sharp {
  /* color: red !important; */
  color: var(--gray) !important;
}

/* Blogs card  */

.blog-heading {
  color: var(--text-color);
}

.sub-heading span {
  color: var(--gray);
}

.sub-heading {
  color: var(--text-color);
}

.module-cont {
  color: var(--gray);
}

.blogs-card-main-container {
  /* padding: 50px; */
  margin-left: 20px;
  background-color: var(--background-color1);
}

.blogs-custom-card {
  width: 260px;
  height: 200px;
  position: relative;
  color: black;
}

.blogs-custom-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* .blogs-card-content {
  width: 240px;
  background-color: var(--card-color);
  position: absolute;
  top: 150px;
  left: 10px;
} */

/* .blogs-custom-card {
  width: 260px;
  height: 350px; 
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.blogs-custom-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
} */

.blogs-card-content {
  width: 240px;
  height: 350px;
  /* Set a fixed height */
  background-color: var(--card-color);
  position: absolute;
  top: 150px;
  left: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.module-cont .material-symbols-sharp {
  color: #3dd598 !important;
}

.row-gap-blogs {
  margin-top: 80px;
  margin-bottom: 270px;
}

.nav-text {
  font-size: 13px;
  color: var(--text-color);
}

.detail-main-text {
  font-size: 32px;
}

/* Blogs Detail Page */

.blog-detail-page-container {
  margin-top: 130px;
}

.detail-page-main-container {
  margin: auto;
  width: 690px;
  margin-top: 150px;
}

.detail-main-heading {
  color: var(--text-color);
}

.detail-page-sub-heading {
  color: var(--text-color);
}

.blog-detail-image-cont {
  width: 690px;
  height: 420px;
}

.blog-detail-image-cont img {
  width: 100%;
}

.detail-main-heading {
  font-size: 28px;
  font-weight: 600;
}

.detail-page-sub-heading {
  line-height: 35px;
}

.blogs-created-date {
  color: var(--label);
  line-height: 35px;
}

.introduction-text p {
  line-height: 20px;
}

.custom-content-style p {
  font-size: 12px;
  color: var(--gray);
}

.conclusion p {
  color: var(--gray);
}

.intro-cont p {
  font-size: 12px;
}

.subDetailText {
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
}

.gray-color {
  color: var(--label);
}

.text-color {
  color: var(--text-grey-color);
}

.related-blogs-image {
  width: 370px;
  height: 170px;
}

.related-blogs-images {
  width: 400px;
  height: 170px;
}

.related-blogs-images img {
  width: 100%;
}

.related-blogs-image img {
  width: 100%;
}

.related-blogs-content {
  color: var(--text-color);
}

.related-blogs-card-heading {
  width: 370px;
}

.blogs-active-user-profile {
  width: 30px;
  height: 30px;
  background-color: var(--buttonHover);
}

.notification-cont .material-symbols-sharp {
  font-size: 30px !important;
}

.mobile-responsive-navbar {
  display: flex;
  justify-content: space-between;
  display: none;
}

.blog-nav-bar {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: var(--background-color3);
  position: fixed;
  top: 65px;
  width: 100%;
  z-index: 1;
  overflow: auto;

  li {
    margin: 0 15px;
  }

  ul {
    width: max-content;
  }
}

.blog-nav-bar::-webkit-scrollbar {
  height: 5px;
}

.blog-nav-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.blog-nav-bar::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #c1c1c1;
}

.blog-nav-bar::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
}

.blog-nav-bar::-webkit-scrollbar:vertical {
  display: none;
}

.text-center .ShimmerContentBlock {
  background-color: black;
}

.blogMain .row {
  width: 100%;
}


/* ************************* media quries ********************** */

@media only screen and (max-width: 414px) {
  .followButton {
    display: none;
  }

  .blogs-filter-search-container {
    display: none !important;
  }

  .blogs-tab {
    width: 100%;
  }

  .blogs-card-main-container {
    width: 100%;
  }

  .mobile-responsive-navbar {
    display: block;
    display: flex;
    margin-bottom: 40px;
  }

  .row-gap-blogs {
    justify-content: center;
  }

  .blogs-custom-card {
    margin-right: -8px;
  }
}



.blogMainContainer {
  /* margin-top: 140px; */
  margin: 140px 20px 20px 20px;

}

.blogSingleCard {
  position: relative;
  margin-right: 10px;
  margin-bottom: 180px;
}

.blogSingleCard .blogContent {
  background-color: var(--darkBG);
  width: 90%;
  padding: 10px;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, 10px);
  height: 200px;

}

.blogContent:hover .blog-heading {
  color: var(--link-color);
}

.blogImage img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  /* border: 5px solid black; */
}

.blogMainContainer .square-shimmer .shimmer-div {
  height: 200px;
}

.blog-description span,
.blog-description p,
.blog-description h1,
.blog-description h2,
.blog-description h3,
.blog-description h4,
.blog-description h5,
.blog-description h6,
.blog-description div {
  /* color: unset; */
  font-family: unset;
  font-size: unset;
}

.blog-description span,
.blog-description p,
.blog-description h1,
.blog-description h2,
.blog-description h3,
.blog-description h4,
.blog-description h5,
.blog-description h6,
.blog-description div {
  color: var(--text-color) !important;
}

.blog-description .MsoNormal {
  margin-bottom: 10px !important;
}

/* .blog-description span,
.blog-description p,
.blog-description div {
  all: unset;
} */

/* .blog-description * {
  all: initial;
}
.blog-description span,
.blog-description p,
.blog-description div {
  all: unset;
}

:not(.blog-description) span,
:not(.blog-description) p {
} */

/* .blog-details-desc h1 {
  font-size: 20px;
}

.blog-details-desc p {
  font-size: 14px;
}

.blog-details-desc b {
  margin-top: 10px;
} */

.blog-description li::marker {
  color: var(--text-color);
}