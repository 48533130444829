@import '../../index.css';

.empty {
    width: 50px;
    height: 50px;
    background-color: red;
}
#demoContainer , #demoContainer1 , #demoContainer2{
    display: none;
}
.output-container {
    margin: auto;
    width: 600px;
    height: 400px;
    border: 1px solid grey;
}
.sweet-alert {
    margin: auto;
    width: 400px;
    height: 300px;
    border: 1px solid black;
}
.form-label {
    color: #000;
}
.modal-footer {
    display: block;
}