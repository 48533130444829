@import '../../index.css';

.setting-container {
  height: 400px;
  width: 200px;
  background-color: #ffffff;
}

.setting-content {
  height: 400px;
  width: 440px;
  background-color: #ffffff;
}

.main-container {
  /* margin-top: 20px; */
}

.setting-container {
  margin-right: 20px;
  padding: 15px;
}

.setting-icon {
  margin-right: 10px;
}

.setting-icon img {
  width: 37.83px;
  height: 37.64px;
}

.mail-text h1 {
  font-size: 12px;
  margin: 0;
  font-family: var(--poppins);
  color: var(--font-grey);
}

.main-text h1 {
  font-size: 14px;
  font-weight: bold;
  color: #002760;
}

.general-text h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.custome-text h3 {
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  color: black;
}

.setting-bottom ul {
  padding-left: 0;
  margin-top: 20px;
}

.empty-cont {
  height: 1.12px;
  background-color: #F1F1F5;
  border: 1px solid #E2E2EA;
}

.general-text {
  color: #3F3F3F;
  font-weight: 700;
  font-size: 14px;
}


/* Form Design */
.form-control {
  border: 0;
  padding: 0;
  border-bottom: 1px solid #DCDBDD;
  border-radius: 0;
  padding-bottom: 8px;
}





.advertiseImage img,
.feature-post img {
  width: -webkit-fill-available;
}

.featured-card {
  background-color: var(--darkBG);
}

.feature-header {
  border-bottom: 1px solid var(--border);
}

.thumb {
  background: var(--gradient-blue);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postview-info .thumb span {
  color: var(--white) !important;
}

.gradient-text {
  position: relative;
  display: inline-block;
}

.container-fluid {
  background-color: var(--mainBg);
}

.gradient-text::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90.22deg, #002760 28.58%, #0062FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.postview-info,
.postData {
  /* border-bottom: 2px solid var(--border); */
  padding: 5px 0;
}

.postData span {
  font-size: 26px !important;
}

.feed-post .col-md-6,
.feed-post .col-md-12 {
  padding: 0;
}

.feed-post .firstImage,
.feed-post .firstImage img,
.feed-post .secondImage img,
.feed-post .thirdImage img {
  height: 100%;
  width: -webkit-fill-available;
}

.feed-post .secondImage,
.feed-post .thirdImage {
  height: 50%;
}

.feed-post {
  position: relative;
}

.addComment {
  position: absolute;
  width: 100%;
  height: 50%;
  background: var(--gradient-gray);
  top: 50%;
}

.addComment1 {
  background: var(--gradient-gray);
  padding: 10px;
}

.addComment1 .commentArea {
  position: unset;
  margin-top: 20px;
}

.material-icon {
  font-family: 'Material Icons Sharp';
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: var(--buttonBase1);
}


.postAction:hover p,
.postAction:hover .material-icon {
  color: var(--buttonBase);
  /* Change the color to whatever you want for the filled icon */
}

.postAction p {
  color: var(--text-color2);
}

.companyType {
  color: var(--text-color1);
}

.badge-date {
  background-color: var(--red1);
  color: var(--redText);
  font-size: 10px;
}

.AdBlocker {
  color: var(--redText);
}

.AdBlockerIcon {
  color: var(--redText) !important;
  width: 100px !important;
  text-align: center;
}

.badge-job {
  background-color: var(--badge-gray-bg);
  color: var(--badge-gray-text);
  padding: 5px 25px;
  font-size: 10px;
}

.badge-job1 {
  background-color: var(--badge-gray-bg);
  color: var(--badge-gray-text);
  padding: 5px 25px;
  font-size: 12px;
}

.location {
  color: var(--gray);
}

.jobAction .mainBtn {
  padding: 4px 60px;
  font-size: 12px;
  font-weight: bold;
}

.jobPost-img .firstImage {
  height: 230px;
}

.jobData h4,
.jobData p {
  color: var(--searchbg);
}

.AddPost {
  color: var(--gray);
}

.featured-card .material-symbols-sharp {
  color: var(--text-color1) !important;
}

.postAdd textarea {
  border: none;
  border-bottom: none;
  color: var(--text-color1) !important;
  font-size: 14px;
  background: var(--background-color3);
}

.postAdd textarea:focus {
  border: none;
}

.postMenu {
  position: relative;
}

.InteractionModal {
  position: absolute;
  top: 20px;
  background-color: var(--searchbg);
  z-index: 999;
}

.InteractionModal .dropdownTxt {
  color: var(--text-color1);
}

.InteractionModal .dropdownPara {
  color: var(--gray)
}

/* .commentBox {
  background: var(--white);
  display: flex;
  margin-left: 10px;
  width: 100%;
  padding: 4px;
  border-radius: 100px;
  width: 100%;
  margin-right: 20px;
} */

.commentBox {
  background: var(--border);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  width: 100%;
  padding: 4px;
  border-radius: 100px;
  margin-right: 20px;
}

.commentBox input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border: none;
  border-radius: 100px;
  background-color: var(--border);
  color: var(--card-text-color);
}

/* .commentBox input {
  background: transparent;
  border: none;
  font-size: 12px;
  outline: none;
  margin-right: auto;
} */

.commentArea {
  display: flex;
  bottom: 15px;
  position: absolute;
  align-items: center;
  width: 100%;
}

.active .bs-stepper-circle {
  background-color: #3d3dca !important;
}

.createProfile {
  width: 500px;
}

.react-datepicker__calendar-icon {
  padding-left: 0 !important;
}

.postAdd .mainBtn {
  height: 40px;
  width: 120px;
  padding: 0 !important;
}

.feedfixedContainer {
  position: fixed;
  width: 18%;
  height: 100%;
  padding-bottom: 90px;
}

.featuredScroll {
  overflow-y: scroll;
  height: 100%;
}

.featuredScroll::-webkit-scrollbar {
  display: none;
}

.likePadding {
  padding: 12px 15px;
}

.postAction .material-icon {
  font-size: 20px;

}



.feed-post .col-6 img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.feed-post .col-4.multiImage img {
  height: 100px;
}

.feed-post .col-12,
.feed-post .col-6,
.feed-post .col-4 {
  padding: 0;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.feed-post {
  padding: 4px;
}

/* .feed-post .col-6:nth-child(1) {
  padding: 0 5px 5px 0;
}

.feed-post .col-6:nth-child(3) {
  padding: 0 5px 0 0;
}

.feed-post .col-12 {
  padding: 0 0 5px 0;
} */
.comment-gradient {
  position: absolute;
  width: -webkit-fill-available;
  bottom: 5px;
  padding-right: 5px;
}

.advertisementContainer a {
  target: _blank !important;
  rel: noopener noreferrer !important;
}

.advertisementContainer ins {
  height: 300px !important;
  margin-bottom: 30px !important;
  text-align: center !important;
}

.ad-container {
  position: sticky;
  top: 70px;
  overflow: hidden;
}

body.modal-open .ad-container {
  position: static !important;
}

.advertisementContainer {
  min-width: 250px;
  /* Set minimum width */
  width: 100%;
  /* Ensure it can scale */
}

.sub-content-container,
.mainProfileContainer {
  position: relative;
}

/* .mainProfileContainer .profileTabContent,
.mainProfileContainer .newsfeedPosts.ad-container {
  position: sticky;
  top: 70px;
  height: calc(100vh - 100px);
  overflow-y: auto;
} */

body.modal-open .mainProfileContainer .profileTabContent,
body.modal-open .mainProfileContainer .newsfeedPosts.ad-container {
  position: static !important;
  /* or any other positioning you prefer */
}


.sub-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* .sub-content-container .mobile-view {
  position: sticky;
  top: 70px;
  height: fit-content;
  z-index: 10;
} */

body.modal-open .sub-content-container .mobile-view {
  position: static !important;
}

/* .sub-content-container .userProductContainer {
  height: calc(100vh - 100px);
  overflow-y: auto;
} */



.advertisementview {
  border: 1px solid var(--border);
}

.advertisementview .adText {
  text-align: center;
  font-size: 20px;
  background-color: var(--background-color3);
}


@media screen and (max-width: 1024px) {


  .sideLinks.leftNav {
    width: 20%;
  }

  .newsfeedPosts {
    width: 100%;
  }

  .feedAdvertise {
    display: none;
  }
}

@media screen and (max-width: 720px) {

  /* .commentBox input {
    width: 180px;
  } */
  .advertisementContainer {
    width: 100%;
    max-width: 320px;
    min-height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .adsbygoogle {
    display: block;
  }


  .menu .sideLinks.leftNav {
    width: 300px;
  }

  .postData {
    margin-top: 10px;
  }

  .jobAction .mainBtn {
    padding: 4px 40px;
  }

  .newsfeedPosts {
    margin-bottom: 60px;
  }

  .addComment {
    height: 80%;
    top: 20%;
  }

  .AddPost {
    font-size: 12px;
  }
}

/* News Feed Image Modal */

/* .allPostContainer .modal.show .modal-dialog {
  margin-top: 10px;
} */
/* .allPostContainer .modal {
  height: 500px;
} */
.postesImage {
  width: 740px;
  height: 550px;
}

.postesImage img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.bgColorModal {
  background-color: var(--gray);
  padding: 0;
  height: 550px;
  /* border-radius: 5px; */
}

.post-comment-sections {
  width: 364px;
  height: 550px;
  background-color: #ffff;
}

.bgColorModal button {
  position: absolute;
  right: -5px;
  top: 15px;
  z-index: 9999;
  border: none;
  background-color: transparent;
  font-size: 30px;
}

.post-comment-color {
  color: var(--gray);
}

.post-comment-main-text p {
  text-align: left;
}

/* .postAdd .modal-dialog {
  width: 700px;
} */

/* css for news feed image modal */

.image_container {
  height: 120px;
  width: 200px;
  border-radius: 6px;
  overflow: hidden;
  margin: 10px;
}

.image_container img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.image_container span {
  top: -6px;
  right: 8px;
  color: red;
  font-size: 28px;
  font-weight: normal;
  cursor: pointer;
}

.post-btn-cont .material-symbols-sharp {
  font-size: 30px !important;
}

.image-modal-cont .modal {
  margin-left: 80px;
}

.post-btn-cont {
  width: 1100px;
  height: 70px;
  background-color: #fff;
  /* border-top: 0.1px solid var(--gray); */
}

.post-button button {
  width: 100px;
  height: 40px;
  background-color: var(--buttonHover);
  border: none;
  color: #fff;
}

.modal-border {
  border: 1px solid var(--border);
  background-color: var(--background-color3);
}

.post-selected-images {
  width: 100%;
  height: 250px;
}

.post-selected-images img {
  width: 100%;
}

.posted-images {
  width: 100px;
  /* height: 100px; */
}

.posted-images img {
  width: 100%;
}

.modalBorder .css-1fdsijx-ValueContainer {
  /* border: 0.5px solid var(--gray); */
  /* margin-right: 185px; */
}

.likeBtn {
  color: red;
}

.comment-user-image {
  height: 34px;
  width: 40px;
  border-radius: 50%;
  /* background-color: #ffff; */
  border: 1px solid gray;
}

.comment-user-image img {
  width: 100%;
}

.error-image-cont {
  width: 100%;
  height: 500px;
}

.error-image-cont {
  margin: auto;
}

.error-image-cont img {
  width: 100%;
}

.error-connection {
  width: 300px;
  height: 300px;
  margin: auto;
}

.error-connection img {
  width: 100%;
}

.error-image-job {
  margin: auto;
  width: 60%;
}



.toast-header .material-symbols-sharp {
  color: orange !important;
}

.toast-header strong {
  color: orange
}

.success-toast .material-symbols-sharp {
  color: green !important;
}

.success-toast strong {
  color: green;
}

.error-toast .material-symbols-sharp {
  color: red !important;
}

.error-toast strong {
  color: red;
}

.featured-card .feature-content {
  margin-bottom: 10px;
}

.update-post-cont {
  background-color: red;
}

/* Shimmer */

body {
  overflow-x: hidden;
}

/* .circle {
  height: 100px;
  width: 100px;
  background-color: red;
} */
.container {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container.dark {
  filter: invert(100%);
}

.box {
  position: relative;
  width: 70vw;
  height: 40vh;
  margin: 20px 0;
  display: block;
  background: transparent;
}

/* .circle {
  position: absolute;
  top: 20px;
  left: 0;
  width: 70px;
  height: 70px;
  border-radius: 100%;
} */

.lines {
  position: absolute;
  width: calc(100% - 150px);
  height: 100%;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line {
  position: relative;
  left: 0;
  width: 100%;
  margin: 2.5px 0;
}

.line:nth-child(2n) {
  height: calc(13% - 5px);
}

.line:nth-child(2n + 1) {
  height: calc(25% - 5px);
}

.s_shimmer {
  background: rgba(0, 0, 0, 0.08);
}

.box.shimmer {
  overflow: hidden;
}

.box.shimmer::after {
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0));
  animation: gradient 1600ms infinite;
  animation-timing-function: cubic-bezier(0.38, 0.55, 0.34, 0.95);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%) rotate(0deg);
  border-radius: 5px;
}

@keyframes gradient {
  100% {
    transform: translateX(100%) rotate(0deg);
  }
}


.shimmer-cont {
  width: 100%;
  background: var(--background-color3);
  /* height: 400px; */
  /* border: 1px solid gray; */
}

.square-shimmer .shimmer-div {
  width: 100% !important;
  height: 500px;
}

.shimmer-title-div {
  width: 200px;
}

.shimmer-header {
  width: 100%;
  padding: 10px;
}

.cemEli {
  background: linear-gradient(to right, #0074ff 8%, #f1f5f9 18%, #0074ff 33%);
}


.multi-color-shimmer .shimmer::before {
  background-image: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.multi-color-shimmer.dark .shimmer::before {
  background-image: linear-gradient(90deg, #333 25%, #444 50%, #333 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.adsRow {
  margin: auto !important;
}

.PeopleNewsFeed {
  height: 200px !important;
}

.PeopleNewsFeed .advertisementContainer ins iframe,
.jobMainContainer .advertisementContainer ins iframe,
.connectionPage .advertisementContainer ins iframe {
  height: 200px !important;
}

.frindsCardContainer .advertisementview {
  /* width: 97% !important; */
  margin: auto !important;
  height: 200px !important;
}

.frindsCardContainer .advertisementview .advertisementContainer {
  height: 200px !important;
}

.Adwrapper,
.AdwrapperwithText {
  border: 1px solid var(--border);
}


.AdwrapperwithText .adText {
  text-align: center;
  font-size: 16px;
  background-color: var(--background-color3);
}

/* Smooth fade effect */
.ad-wrapper {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.ad-wrapper.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.connectionPage .Adwrapper ins {
  height: 150px !important;
}


.ARCard {
  border: 1px solid var(--border);
  background-color: var(--background-color3);
  padding: 5px;
}

.HeadingIcon {
  font-size: 15px !important;
  /* margin-top: 2px; */
}

.warningText {
  background-color: #f2d57c;
  font-size: 14px;
  padding: 8px;
}