@import '../../index.css';

body {
  /* font-family: sans-serif; */
}

:root {
  --font-400: 400;
  --font-700: 700;
  --font-16px: 16px;
  --grey-: #797979;
  --orange: #C44536;
}

.main-container-resume {
  width: 595px;
  height: 842px;
  padding: 30px 0px 30px 30px;
  margin: auto;
  border: 0.3px solid grey;
  background: url(../../assets/Images/Resume/logo-removebg\ 2.png);
}

.logo {
  width: 100px;
  height: 58px;
}

.logo img {
  width: 100%;
}

.emptyCont {
  height: 1px;
  width: 129px;
  background-color: red;
}

.userNameText h1 {
  font-size: 24px;
  font-weight: var(--font-400);
  line-height: 24px;
  margin-bottom: 0;
  color: var(--dark-blue-color);
}

.subText h1 {
  font-weight: var(--font-700);
}

.ProfileText h1 {
  font-size: var(--font-16px);
  font-weight: var(--font-400);
  line-height: 25px;
  color: var(--dark-blue-color);
}

.companyText h4 {
  font-size: var(--font-16px);
  font-weight: var(--font-700);
  line-height: 25px;
  color: var(--dark-blue-color);
}

.addText h6 {
  font-size: 12px;
  font-weight: var(--font-400);
  color: var(--grey-);
}

.mainText h1 {
  font-size: 12px;
  font-weight: var(--font-700);
  color: var(--dark-blue-color);
}

.engCollegeText h1 {
  font-size: 10px;
  font-weight: var(--font-700);
  color: var(--dark-blue-color);
}

.degreeText h4 {
  font-size: 10px;
  font-weight: var(--font-400);
  line-height: 15px;
  color: var(--orange);
}

.educationBottom {
  width: 121px;
}

.degreeText {
  width: 121px;
}

.engCollegeText h1 {
  margin-bottom: 0;
}

.degSubCont {
  width: 170px;
}

.schoolText {
  width: 110px;
}

.customText h1 {
  font-size: 12px;
  font-weight: 400;
}

.collegeLogo {
  margin-right: 10px;
}

/* Right section */

.contactText h1 {
  font-size: 12px;
  font-weight: var(--font-700);
  color: var(--dark-blue-color);
}

.mobileNumber h4 {
  font-size: 12px;
  font-weight: var(--font-700);
  margin-bottom: 2px;
  color: var(--dark-blue-color);
}

.homeText {
  font-weight: var(--font-400);
}

.email h4 {
  color: var(--orange);
}

.summaryText h4 {
  font-size: 12px;
  font-weight: var(--font-400);
  color: var(--dark-blue-color);
}

.soulText {
  font-size: 12px;
  font-weight: var(--font-700);
  color: var(--orange);
}

.skillDetail {
  list-style-type: disc;
  margin: 0;
  padding: 0;
}

.skillDetail li {
  color: var(--orange);
}

.skillDetail li::before {
  content: "\2022";
  color: var(--dark-blue-color);
  margin-right: 0.5em;
  font-size: 30px;
}

.listStyle {
  display: flex;
  font-size: 12px;
  font-weight: var(--font-700);
  line-height: 20px;
}

.companytxt {
  font-size: 16px;
  font-weight: var(--font-700);
  line-height: 20px;
  color: var(--dark-blue-color);
}

.companyName h1 {
  font-size: 16px;
  font-weight: var(--font-400);
  line-height: 20px;
  color: var(--dark-blue-color);
  margin-bottom: 0;
}

.workSubText {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-blue-color);
}

.companyName {
  width: 215px;
}

.companyName h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--orange);
}

.companyName h2 {
  margin-bottom: 0;
}

.subText {
  width: 155px;
}

.subText h3 {
  margin-bottom: 0;
}

.companyName h6 {
  font-size: 10px;
  font-weight: 400;
  line-height: 25px;
  color: var(--grey-);
}

.skillsExpertize {
  width: 282px;
}

.emptyLine {
  width: 100%;
}

.skillList {
  list-style-type: disc;
  margin: 0;
  padding: 0;
}

.skillList li::before {
  content: "\2022";
  color: var(--dark-blue-color);
  margin-right: 0.1em;
  font-size: 30px;
}

.skillList li {
  color: var(--dark-blue-color);
  display: flex;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}

.hobbiesText h1 {
  font-size: 8px;
  font-weight: 400;
  line-height: 14px;
}