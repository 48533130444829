@import "../../index.css";


.jobSearch .css-b62m3t-container {
  border: none !important;
}

.searchInput .css-1ch4rtc-control {
  border: none !important;
}

.Jobfilter .accordion-item,
.Jobfilter .accordion-button:not(.collapsed),
.Jobfilter .accordion-button {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}

.Jobfilter ul {
  padding-left: 0;
}

.jobCard {
  background: var(--background-color3);
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;  */
  /* height: 100%;  */
}

.jobCard .material-icon {
  color: var(--gray);
}

.jobCard .badge-job {
  padding: 5px 12px;
  text-wrap: wrap;
}

/* .jobSearch .css-wsp0cs-MultiValueGeneric {
  width: 100px !important;
} */

.jobCard .jobAction .mainBtn {
  padding: 5px;
  width: 140px;
  margin-right: 10px;
  height: 40px;
}

.jobCard .jobAction .mainBtn4 {
  padding: 11px;
  width: 110px;
}


.jobCard .circle-container {
  margin: 0;
}

.jobCard .text {
  margin-left: 20px;
}

.filter-count {
  font-size: 10px;
  background-color: #f1f1f5;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Feature job  */

.feature-card-main-text {
  font-size: 18px;
}

.feature-image {
  width: 100%;
  height: 80px;
}

.feature-image img {
  width: 100%;
}

.feature-custom-cards {
  height: 100px;
  background-color: var(--background-color3);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.feature-card-image {
  width: 40px;
  height: 40px;
}

.feature-card-image img {
  width: 100%;
}

.card-address-text {
  color: var(--gray);
}

.jobSearch .searchInput {
  background-color: var(--background-color3) !important;
  border-right: 1px solid var(--border);
}

.jobSearch .css-b62m3t-container {
  width: 100%;
}

.jobSearch .mainBtn1 {
  /* height: 54px; */
}

.jobSearch .searchInput .material-symbols-sharp {
  color: var(--text-color2) !important;
}

.button-cont {
  margin-right: 40px;
}

.feature-custom-cards .button-cont {
  margin-right: 10px;
}

.card-save-cont {
  width: 21px;
  height: 21px;
  border: 0.5px solid var(--border);
}

.card-save-image {
  width: 9px;
  height: 9px;
}

.card-save-image img {
  width: 100%;
  margin-bottom: 5px;
}

.weeks-text {
  color: red;
}

.days-text {
  color: var(--gray);
}

.rejectBtn button {
  /* padding: 4px 14px; */
  font-size: 9px;
}

/* Job Details */

.SingleJob {
  background-color: var(--background-color3);
  position: relative;
}

.company-header-image {
  width: 100%;
  height: 100px;
  position: relative;
}

.company-header-image img {
  width: 100%;
}

.company-sub-image {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50px;
  top: 140px;
}

.company-sub-image img {
  width: 100%;
}

.card-save-image {
  width: 9px;
  height: 9px;
  /* border: 1px solid #e0dede; */
}

.card-save-image .material-symbols-sharp {
  color: var(--gray) !important;
  font-size: 17px !important;
}

.bookmark-cont {
  width: 20px;
  height: 20px;
  border: 1px solid #d6d2d2;
}

.bookmark-cont .material-symbols-sharp {
  font-size: 16px !important;
  padding-left: 2px;
  color: var(--gray) !important;
}

.collaps-image-cont {
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 80px;
}

.collaps-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.collaps-image:nth-child(2) {
  left: 16px;
}

.collaps-image:nth-child(3) {
  left: 31px;
}

.collaps-image:nth-child(4) {
  left: 46px;
}

.collaps-image:nth-child(5) {
  left: 61px;
}

.collaps-image:nth-child(6) {
  left: 77px;
}

.collaps-more {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
}

.collaps-more .material-symbols-sharp {
  font-size: 10px !important;
}

.employee-custom-details h6 {
  color: #b5b5be;
}

.overview-cont p {
  line-height: 28px;
}

.job-desc-round {
  width: 10px;
  height: 10px;
  border: 1.7px solid #002760;
  border-radius: 50%;
}

.job-desc-text {
  color: var(--badge-gray-text) !important;
}

.apply-btn {
  border: none;
  background-color: var(--buttonHover);
  color: white;
  font-size: 10px;
  padding: 0px 35px;
  height: 30px;
}

.msgButton {
  background-color: var(--border-color);
  color: var(--badge-gray-text);
}

.company-header-image,
.company-sub-image {
  position: absolute;
  top: 0;
  left: 0;
}

.company-sub-image {
  top: 150px;
  left: 50px;
}

.single-job-main-content {
  position: relative;
  /* margin-top: 230px; */
}

.job-header .filterButton {
  display: none;
}

/* .jobSearch svg {
    display: none;
} */

#accordionExample {
  background-color: var(--background-color3);
}

.css-1xc3v61-indicatorContainer .css-tj5bde-Svg {
  display: none !important;
}

.Jobfilter .accordion-button {
  color: var(--text-color);
}

.jobPosting {
  width: 200px;
}

.jobdropdown {
  position: absolute;
  z-index: 1;
  width: 92.5%;
}

.jobMainContainer {
  margin-top: 70px;
}

.jobMainContainer {
  position: relative;
}

.jobMainContainer .advertiseImage {
  position: sticky;
  top: 80px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

body.modal-open .jobMainContainer .advertiseImage {
  position: static !important;
}

@media screen and (max-width: 720px) {
  .searchjobAds .advertisementContainer ins {
    display: "block";
    width: "100%";
    max-width: "320px";
    min-height: "150px";
    margin: "0 auto";
  }

  .jobdropdown {
    position: unset;
  }

  .jobMainContainer {
    margin-top: 0px;
  }

  .job-header .filterButton {
    display: flex;
  }

  .jobSearch .mainBtn1 {
    width: 100%;
  }

  .jobSearch .col {
    margin-bottom: 20px;
  }

  .tabButton.desktopView {
    display: none !important;
  }

  .Jobfilter {
    display: none;
  }

  .job-header {
    margin-top: 20px;
  }

  .company-sub-image {
    top: 50px;
    left: 30px;
  }

  .row.rootLayout.mobileView .jobCreate .col-md-12 {
    padding-right: 1rem !important;
  }

  /* .jobCreate .modal-content {
    margin-right: 35px;
  } */
  .jobSearch .css-wsp0cs-MultiValueGeneric {
    width: 40px !important;
  }
}

.css-1jqq78o-placeholder {
  font-size: 13px;
}

.appliedBtn span {
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.job-header .search-container {
  width: 100%;
  background-color: var(--background-color3);
}

.jobAction .mainBtn4 a {
  color: var(--badge-gray-text) !important;
}

.css-13cymwt-control {
  /* background-color: var(--background-color3) !important; */
}

.job-card-post-date small {
  color: var(--heading-color);
}

.job-name-details {
  background-color: var(--heading-color);
  color: var(--background-color);
}

.job-company-name {
  /* margin-right: 90px; */
}

.jobAd {
  width: -webkit-fill-available;
  /* height: 100px; */
  margin-bottom: 20px;
}