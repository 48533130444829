/* .toggle-container {
    position: relative;
    top: -15px;
    left: -5px;
}

.guestMode .toggle-container {
    top: 0;
}

.toggle-container label {
    position: relative;
}

.toggle {
    visibility: hidden;
}

.toggle+label {
    color: transparent;
}

.toggle+label::before {
    content: '';
    position: absolute;
    height: 40px;
    width: 80px;
    border-radius: 100px;
    background-color: var(--toggle);
}

.toggle+label::after {
    content: '';
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    background-color: var(--buttonHover);
    left: 0.6em;
    top: 0.3em;
    transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}

.toggle:checked+label::after {
    transform: translateX(100%);
    z-index: 1;
    background-image: url('../Images/Header/moon.png');
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
}

.toggle+label::before {
    z-index: 0;
}

.toggle:not(:checked)+label::after {
    z-index: 1;
    background-image: url('../Images/Header/sun.png');
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1313px) {
    .toggle-container {
        top: 10px;
    }
} */



.toggle-container {
    /* position: relative;
    top: -15px;
    left: -5px; */
    text-align: center;
}

.guestMode .toggle-container {
    top: 0;
}

.toggle-container label {
    position: relative;
}

.toggle {
    visibility: hidden;
}

.toggle+label {
    color: transparent;
}

.toggle+label::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 60px;
    border-radius: 100px;
    background-color: var(--mainBg);
}

.toggle+label::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    background-color: var(--buttonHover);
    left: 0.6em;
    top: 0.3em;
    transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}

.toggle:checked+label::after {
    transform: translateX(100%);
    z-index: 1;
    background-image: url('../Images/Header/moon.png');
    background-position: center;
    /* background-size: contain; */
    background-size: 20px;
    background-repeat: no-repeat;
}

.toggle+label::before {
    z-index: 0;
}

.toggle:not(:checked)+label::after {
    z-index: 1;
    background-image: url('../Images/Header/sun.png');
    background-position: center;
    /* background-size: contain; */
    background-size: 20px;
    background-repeat: no-repeat;
}

@media screen and (min-width: 1313px) {
    .toggle-container {
        top: 10px;
    }
}