.CreateCompany input,
.CreateCompany textarea,
.CreateCompany select {
    width: 100%;
}

.CreateCompany .css-13cymwt-control {
    border-bottom: 1px solid var(--border) !important;
    border-radius: 0 !important;
}
.settings{
    padding: .5rem 1.5rem;
}
.setting-header h5 {
    padding: 20px;
}